import type { ApolloError } from '@apollo/client';
import { User } from 'mission-control-common-components';
import { useMemo } from 'react';
import type { TableInstance } from 'react-table';

import type { PermissionsNames } from '../../../../constants';
import { TraffickingTableName } from '../../../../constants';
import { useFiltersContext } from '../../../../contexts/FilterContext';
import { MAX_LIMIT, ResultKeys } from '../../constants';
import { generateQueryVariables, getSelectedRowIdsFromState } from '../../utils';
import { getAdsColumns, getCampaignsColumns, getLineItemsColumns } from '../columns';
import type {
  AdModel,
  AdNode,
  CampaignModel,
  CampaignNode,
  EntityModel,
  LineItemModel,
  LineItemNode,
} from '../modelConverters';
import { adNodeToModel, campaignNodeToModel, lineItemNodeToModel } from '../modelConverters';
import { GET_ADS, GET_CAMPAIGNS, GET_LINEITEMS_BY_CAMPAIGNID_LIST } from './queries';
import useTraffickerExportTable from './useTraffickerExportTable';

type ExportAllState = {
  fullTableInstance?: TableInstance<EntityModel>;
  loadFullTableInstance: () => void;
  loading: boolean;
  error?: ApolloError;
};

function useTraffickerExportState(tableName: TraffickingTableName): ExportAllState {
  const permissions = User.getPermissions<PermissionsNames>();
  const { filters } = useFiltersContext();

  const campaignQueryVariables = generateQueryVariables(
    {
      ...filters,
      searchTerm: filters?.campaigns?.searchTerm || '',
      sortOption: filters?.campaigns?.sortBy,
      tableName: TraffickingTableName.campaigns,
      selectedRowIds: getSelectedRowIdsFromState(filters.campaigns?.selectedRowIds || []),
    },
    MAX_LIMIT
  );

  const lineItemQueryVariables = generateQueryVariables(
    {
      ...filters,
      searchTerm: filters?.lineItems?.searchTerm || '',
      sortOption: filters?.lineItems?.sortBy,
      tableName: TraffickingTableName.lineItems,
      selectedRowIds: getSelectedRowIdsFromState(filters.lineItems?.selectedRowIds || []),
      campaignRowsIds: getSelectedRowIdsFromState(filters.campaigns?.selectedRowIds || []),
    },
    MAX_LIMIT
  );

  const adsQueryVariables = generateQueryVariables(
    {
      ...filters,
      searchTerm: filters?.ads?.searchTerm || '',
      sortOption: filters?.ads?.sortBy,
      tableName: TraffickingTableName.ads,
      selectedRowIds: getSelectedRowIdsFromState(filters.ads?.selectedRowIds || []),
      campaignRowsIds: getSelectedRowIdsFromState(filters.campaigns?.selectedRowIds || []),
      lineItemRowIds: getSelectedRowIdsFromState(filters.lineItems?.selectedRowIds || []),
    },
    MAX_LIMIT
  );

  const campaignsColumns = useMemo(() => {
    return getCampaignsColumns(permissions?.readTraffickingChangeLog);
  }, [permissions?.readTraffickingChangeLog]);

  const lineItemsColumns = useMemo(() => {
    return getLineItemsColumns();
  }, []);

  const adsColumns = useMemo(() => {
    return getAdsColumns();
  }, []);

  const { tableInstance: campaigns, loadData: loadCampaigns, loading: campaignsLoading } = useTraffickerExportTable<
    CampaignNode,
    CampaignModel
  >(GET_CAMPAIGNS, ResultKeys.campaigns, campaignNodeToModel, campaignsColumns, TraffickingTableName.campaigns);

  const { tableInstance: lineItems, loadData: loadLineItems, loading: lineItemsLoading } = useTraffickerExportTable<
    LineItemNode,
    LineItemModel
  >(
    GET_LINEITEMS_BY_CAMPAIGNID_LIST,
    ResultKeys.lineItems,
    lineItemNodeToModel,
    lineItemsColumns,
    TraffickingTableName.lineItems
  );

  const { tableInstance: ads, loadData: loadAds, loading: adsLoading } = useTraffickerExportTable<AdNode, AdModel>(
    GET_ADS,
    ResultKeys.ads,
    adNodeToModel,
    adsColumns,
    TraffickingTableName.ads
  );

  switch (tableName) {
    case TraffickingTableName.campaigns:
      return {
        fullTableInstance: campaigns as TableInstance<EntityModel>,
        loadFullTableInstance: (): void => loadCampaigns({ variables: campaignQueryVariables }),
        loading: campaignsLoading,
      };
    case TraffickingTableName.lineItems:
      return {
        fullTableInstance: lineItems as TableInstance<EntityModel>,
        loadFullTableInstance: (): void => loadLineItems({ variables: lineItemQueryVariables }),
        loading: lineItemsLoading,
      };
    case TraffickingTableName.ads:
      return {
        fullTableInstance: ads as TableInstance<EntityModel>,
        loadFullTableInstance: (): void => loadAds({ variables: adsQueryVariables }),
        loading: adsLoading,
      };
  }
}

export default useTraffickerExportState;
