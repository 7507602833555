import type { Cell, Column } from 'react-table';

import type { AdSequence, Nullable } from '../../../../models';
import { fromEmailToName } from '../../../../utils/common';
import {
  enumToTitleCase,
  formatArray,
  formatDate,
  formatDateTimeToZonedTime,
  formatDollarAmount,
  formatPercentage,
  formatStringDate,
  formatWeightToPercentage,
} from '../../../../utils/formatting';
import { NEW_SEQUENCE_LABEL } from '../../../Sequence/constants';

export type CustomColumnConfig<T extends Object = {}> = Column<T> & {
  isEditable?: boolean;
  className?: string;
  disableHiding?: boolean;
};

export type CellWithCustomColumnConfig<T extends object = {}> = Cell<T> & {
  column: CustomColumnConfig<T>;
};

export function stringToDateTimeString({ value }: { value: Nullable<string> }): Nullable<string> {
  return value ? formatDate(new Date(value)) : null;
}

export function formatStartEndDateString({ value }: { value: Nullable<string> }): Nullable<string> {
  return value ? formatStringDate(value) : null;
}

export function microCurrencyToDollarAmount({ value }: { value: Nullable<number> }): Nullable<string> {
  if (value === null) return null;
  return formatDollarAmount(value / 1000000);
}

export function numberToDollarAmount({ value }: { value: Nullable<number> }): Nullable<string> {
  return value ? formatDollarAmount(value) : null;
}

export function numberToPercentage({ value }: { value: Nullable<number> }): Nullable<string> {
  return value ? formatPercentage(value) : null;
}

export function weightToPercentage({ value }: { value: Nullable<number> }): string {
  return formatWeightToPercentage(value || 0);
}

export function getIdFromTableCell<T extends { id: string }>(cell: Cell<T>): string {
  return cell.row.original.id;
}

export function getPublisherTargetingList({ value }: { value: Nullable<string[]> }): string {
  return value && value.length > 0 ? formatArray(value?.map((item) => enumToTitleCase(item))) : '';
}

export function boolToYes({ value, isNoShown }: { value: Nullable<boolean>; isNoShown?: boolean }): Nullable<string> {
  if (value) return 'Yes';

  return isNoShown ? 'No' : null;
}

export function formatUTCToDefaultTimezone({ value }: { value: Nullable<string> }): Nullable<string> {
  return value ? formatDateTimeToZonedTime(value) : null;
}

export const getAdSequenceName = (adSequence?: AdSequence | null): string => {
  if (!adSequence) return '';

  return adSequence.name || NEW_SEQUENCE_LABEL + '1';
};

export const getAccountRelatedColumnAccessor = (value?: string): string => (value ? fromEmailToName(value) : '');

export const getMetricsRelatedColumnAccessor = (value?: Nullable<number>): string =>
  value ? Math.round(value).toLocaleString() : '';

export const getMetricsPercentRelatedColumnAccessor = (value?: Nullable<number>): number | null =>
  value ? value * 100 : null;

export const getLineItemThreshold = (value: Nullable<number>): string | null =>
  Number.isFinite(value) ? `${value}%` : null;
