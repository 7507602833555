import { some } from 'lodash';
import { useEffect } from 'react';
import { useRef, useState } from 'react';

import type { DropdownOption, DropdownOptionGroup } from '../../../Dropdown';
import type { OptionTypeWithExtras } from '../../MultiSelectDropdown';
import type { MultiSelectContainerProps } from '../MultiSelectContainer';

interface UseMultiSelectContainerResult<T = string> {
  groupedOptions?: (DropdownOption<OptionTypeWithExtras<T>> | DropdownOptionGroup<OptionTypeWithExtras<T>>)[];
}

export const CURRENTLY_SELECT_LABEL = 'currently selected';

export const SELECT_ALL_OPTIONS_VALUE = '*';
export const CLEAR_SELECTED_ITEMS_VALUE = 'CLEAR';

export const CLEAR_OPTION: DropdownOption<typeof CLEAR_SELECTED_ITEMS_VALUE> = {
  label: 'Clear selected items',
  value: CLEAR_SELECTED_ITEMS_VALUE,
};

export const makeSelectAllOption = (multiOptionLabel: string): DropdownOption<typeof SELECT_ALL_OPTIONS_VALUE> => ({
  label: multiOptionLabel,
  value: SELECT_ALL_OPTIONS_VALUE,
});

/**
 * This hook groups the dropdown options with any currently-selected items at the top along with a "clear" option
 * The grouping only executes when the dropdown is opened/rendered
 * Similarly, the "clear" option only renders if the dropdown has a value when it is opened.
 * if a value for multiOptionLabel is not provided no select all option will exist.
 */
export default function useMultiSelectContainer<T = string>(
  props: MultiSelectContainerProps<T>
): UseMultiSelectContainerResult<T> {
  const { multiOptionLabel, multipleSelectControls } = props;
  const [groupedOptions, setGroupedOptions] = useState<
    Array<DropdownOption<OptionTypeWithExtras<T>> | DropdownOptionGroup<OptionTypeWithExtras<T>>>
  >([...props.options]);

  const initiallySelected = useRef([
    ...(props.value.length && !multipleSelectControls ? [CLEAR_OPTION] : []),
    ...props.value,
  ]);

  useEffect(() => {
    const getUnselectedOptionList = (): (
      | DropdownOption<OptionTypeWithExtras<T>>
      | DropdownOptionGroup<OptionTypeWithExtras<T>>
    )[] => {
      // Filter out options that are currently selected
      const filteredOptions =
        props.options?.filter((optionOrGroup) => {
          if ('value' in optionOrGroup) {
            return !some(initiallySelected.current, optionOrGroup);
          }
          return true;
        }) || [];

      // Filter the option groups as well
      return filteredOptions.map((optionOrGroup) => {
        if ('value' in optionOrGroup) {
          return optionOrGroup;
        }

        return {
          label: optionOrGroup.label,
          options: optionOrGroup.options.filter((option) => !props.value.includes(option)),
        };
      });
    };

    let options = !props.inputValue
      ? [
          {
            label: CURRENTLY_SELECT_LABEL,
            options: initiallySelected.current,
          },
          ...getUnselectedOptionList(),
        ]
      : props.options;

    // add select all option if a value is provided for multiOptionLabel.
    if (multiOptionLabel && !multipleSelectControls) {
      options = [
        {
          label: 'select all',
          options: [makeSelectAllOption(multiOptionLabel)],
        },
        ...options,
      ];
    }

    setGroupedOptions([...options]);
  }, [multiOptionLabel, props.options, props.value, props.inputValue, multipleSelectControls]);

  return { groupedOptions };
}
