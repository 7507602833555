import './PauseCreativeBuilder.scss';

import { useFormikContext } from 'formik';
import React from 'react';

import type { PauseCreativeInputV5 } from '../../../../apis/graphql';
import { removeTypeNamesFromObject } from '../../../../apis/graphql';
import { ColorPicker, TextField } from '../../../../common/Form';
import AssetImporterField from '../../../../common/Form/AssetImporterField';
import type { CreativeReviewStatus } from '../../../../configs';
import bem from '../../../../utils/bem';
import type { AdFormValues } from '../../adFormik';
import { AdFormCreativeFields } from '../../adFormik/fields';
import { SiteServedFooter } from '../components';
import CreativeBuilder from '../CreativeBuilder';
import type { CreativeBuilderProps } from '../CreativeBuilder/CreativeBuilder';
import { useCreativeData } from '../hooks/useCreativeData';
import type { CreativesBuilderValues } from '../types';
import { CreativeEditMode } from '../types';

const [, element] = bem('pause-creative');

const PAUSE_ASPECT_RATIO = 16 / 9;

export const constructPauseCreativeInput = ({
  name,
  image,
  altText,
  accent,
}: CreativesBuilderValues): PauseCreativeInputV5 => {
  const input: PauseCreativeInputV5 = {
    name,
    type: 'PAUSE',
    altText: altText!,
    accent: removeTypeNamesFromObject(accent!),
  };

  if (image?.id !== '') input.imageId = image?.id;

  return input;
};

function PauseCreativeBuilder({ isPreview, adTypeDimensions, editMode }: CreativeBuilderProps): React.JSX.Element {
  const {
    values: { creative },
  } = useFormikContext<AdFormValues>();

  const {
    data: { assets },
    loading,
  } = useCreativeData(creative);
  return (
    <CreativeBuilder classNameModifier="pause" isPreview={isPreview} editMode={editMode}>
      <div className={element('grid')}>
        <div className={element('left-column')}>
          <AssetImporterField
            adTypeDimensions={adTypeDimensions?.display}
            aspectRatio={PAUSE_ASPECT_RATIO}
            assetType="DISPLAY"
            formFieldName={AdFormCreativeFields.Image}
            id="pause-image-asset"
            isPreview={isPreview}
            label="Display Image Asset"
            secondaryLabel={isPreview ? undefined : ' (Required)'}
            readonly={editMode !== CreativeEditMode.full}
          />
        </div>
        <div className={element('right-column')}>
          <TextField
            id="image-alt-text"
            formFieldName={AdFormCreativeFields.AltText}
            label="Image Alt Text"
            secondaryLabel={isPreview ? undefined : ' (Required)'}
            disabled={editMode === CreativeEditMode.readonly}
            required
          />
          <ColorPicker
            id="accent-color"
            formFieldName={AdFormCreativeFields.Accent}
            label="Background Accent Color"
            secondaryLabel={isPreview ? undefined : ' (Required)'}
            placeholder="eg. 45,189,157"
            disabled={editMode === CreativeEditMode.readonly}
            required
          />
        </div>
      </div>
      <div className={element('footer')}>
        <SiteServedFooter
          creativeReview={creative.review as CreativeReviewStatus}
          selectedAsset={assets[0]}
          assets={assets}
          isLoading={loading}
          hrLine={false}
          creativeStatus={creative?.status}
        />
      </div>
    </CreativeBuilder>
  );
}

export default PauseCreativeBuilder;
