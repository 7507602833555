import type {
  Get_Lineitems_By_Campaignid_ListQuery,
  LineItemTraffickingColumnsFragment,
  ScheduleV5,
} from '../../../../apis/graphql';
import {
  convertAdProduct,
  convertBillableThirdParty,
  convertDeliveryTier,
  convertFrequencyCapUnitType,
  convertLineItemDerivedStatus,
  convertLineItemStatus,
  convertPacingType,
  convertReview,
} from '../../../../configs';
import type { LineItem, Nullable, OrderOmsLink, TargetingTermValue } from '../../../../models';

export type LineItemNode = NonNullable<Get_Lineitems_By_Campaignid_ListQuery['lineItems']['edges'][number]>['node'];

export type LineItemModel = Pick<
  LineItem,
  | 'id'
  | 'name'
  | 'pacingType'
  | 'review'
  | 'goal'
  | 'status'
  | 'subStatus'
  | 'tier'
  | 'priority'
  | 'priorityValue'
  | 'adProduct'
  | 'unitCost'
  | 'unitCostDto'
  | 'costMethod'
  | 'billableThirdParty'
  | 'creativeMaxSeconds'
  | 'revenue'
  | 'metrics'
  | 'startDate'
  | 'endDate'
  | 'deliveryBufferPercent'
  | 'frontLoadPercent'
  | 'frequencyCapList'
  | 'lineItemSequenceId'
  | 'createdAt'
  | 'updatedAt'
  | 'trackingId'
  | 'hasZeroDeliveryRisk'
  | 'hasMisalignedSchedule'
  | 'deliveryGoal'
  | 'displayPublisherTarget'
  | 'isCoppaOrCaru'
  | 'isMakegood'
  | 'draxDeal'
  | 'isAddedValue'
  | 'exclusiveBlock'
  | 'lineItemThreshold'
  | 'targetingRule'
  | 'country'
  | 'pacingMetrics'
> & {
  campaign: Nullable<LineItemTraffickingColumnsFragment['campaign']>;
  schedule: Nullable<Pick<ScheduleV5, 'timezone' | 'dateRangeList' | 'dayPartList' | 'deliveryInViewerTime'>>;
  parentLineOmsLink: Nullable<Pick<OrderOmsLink, 'id'>>;
  lineOmsLink: Nullable<Pick<OrderOmsLink, 'id'>>;
  orderLineItemDto: Nullable<LineItemTraffickingColumnsFragment['orderLineItemDto']>;
  magniteDealDto: Nullable<LineItemTraffickingColumnsFragment['magniteDealDto']>;
  metadata: Nullable<LineItemTraffickingColumnsFragment['metadata']>;
};

export function lineItemNodeToModel(node: LineItemNode): LineItemModel {
  return {
    ...node,
    adProduct: convertAdProduct(node.adProduct),
    billableThirdParty: node.billableThirdParty ? convertBillableThirdParty(node.billableThirdParty) : null,
    // costMethod: node ? convertCostMethod(node.cost) : null,
    campaign: node.campaign || null,
    schedule: node.schedule || null,
    pacingType: convertPacingType(node.pacingType),
    review: convertReview(node.review),
    priorityValue: node.priorityValue || 0,
    status: convertLineItemStatus(node.status),
    subStatus: node.subStatus ? convertLineItemDerivedStatus(node.subStatus) : null,
    tier: {
      ...node.tier,
      name: convertDeliveryTier(node.tier.name),
    },
    frequencyCapList: node.frequencyCapList.map((fC) => ({
      ...fC,
      duration: fC.duration || 0,
      durationUnit: convertFrequencyCapUnitType(fC.durationUnit),
    })),
    lineItemThreshold: node.lineItemThreshold,
    exclusiveBlock: node.exclusiveBlock
      ? {
          ...node.exclusiveBlock,
          id: node.exclusiveBlock?.id ?? null,
          name: node.exclusiveBlock?.name ?? null,
          lineItemIdList: node.exclusiveBlock?.lineItemIdList ?? [],
          blockedIndustryList: node.exclusiveBlock?.blockedIndustryList ?? [],
          targetingTermValues: (node.exclusiveBlock?.targetingTermValues ?? []) as TargetingTermValue[],
        }
      : null,
    pacingMetrics: node.pacingMetrics,
  };
}
