import { gql } from '@apollo/client';

// Ad on Trafficking Page
export const AD_TRAFFICKING_COLUMN = gql`
  fragment adTraffickingColumn on AdV5 {
    __typename
    id
    name
    type
    review
    rating
    status
    startDate
    endDate
    unDeletable
    schedule {
      timezone
      deliveryInViewerTime
      dateRangeList {
        startDate
        endDate
        pacingShare
      }
      dayPartList {
        activeDayList
        firstActiveHour
        lastActiveHour
      }
    }
    frequencyCapList {
      type
      limit
      duration
      durationUnit
      enabled
    }
    createdAt
    creative {
      id
      metadata {
        countryCodes
        createdAt
        creativeId
        deleted
        isLegalApproved
        updatedAt
      }
    }
    updatedAt
    lineItem {
      startDate
      endDate
      id
      name
      adProduct
      exclusiveBlock {
        id
        name
        lineItemIdList
        blockedIndustryList {
          id
          name
        }
        targetingTermValues {
          category {
            id
            name
            displayName
          }
          dimension {
            id
            name
            displayName
          }
          value {
            id
            name
            displayName
          }
          include
        }
      }
      parentLineOmsLink {
        id
      }
      campaign {
        name
        id
        adAccount {
          id
          timezone
          organizationId
        }
        campaignTagList {
          id
          type
          url
        }
        crmLink {
          id
          name
        }
        startDate
      }
      schedule {
        timezone
        dateRangeList {
          startDate
          endDate
          pacingShare
        }
        dayPartList {
          firstActiveHour
          lastActiveHour
          activeDayList
        }
        deliveryInViewerTime
      }
      displayPublisherTarget
      billableThirdParty
      isCoppaOrCaru
      country
    }
    viewabilityVendorList
    sequence
    adRotation {
      rotationName
      rotationId
      weight
    }
    adSequence {
      adId
      sequenceId
      order
      name
      updatedAt
    }
    trackingId
    displayPublisherTarget
    hasMisalignedSchedule
    adTagList {
      id
      type
      event
      url
      isVerified
      unDeletable
      isIas
    }
  }
`;
