import React from 'react';

import bem from '../../../../../../utils/bem';
import { titleCase } from '../../../../../../utils/formatting';
import { GreenPlus } from '../../../../../assets';
import Loader from '../../../../../Loader';
import { TermStatus } from '../../../../restructureData/types';
import type { CellItemsProps } from '../index';
import useGetTargetingValues from './useGetTargetingValues';

const [block, element] = bem('multiple-change-log-cell');

export interface ValueSet {
  status?: TermStatus;
  include?: boolean;
  value?: string | null;
}

export interface Term {
  dimension?: string;
  operation?: string;
  valueSet?: ValueSet[] | string[];
  not?: boolean;
  'value-set'?: ValueSet[] | string[];
}
interface TermListProps {
  term: Term;
  action?: { removal: boolean; addition: boolean };
  isMultiTerm?: boolean;
  termIndex?: number;
}

export function getModifier(removed: boolean, added: boolean): string {
  if (removed) return 'removed';
  if (added) return 'added';

  return '';
}

export function getInclusion(isIncluded: boolean): string {
  return isIncluded ? 'Include' : 'Exclude';
}

export function getValue(value: ValueSet | string): string {
  if (typeof value === 'string') {
    return value;
  } else if (!value?.value) {
    return '';
  } else if (value?.value) {
    return value?.value;
  }

  return '';
}

interface TermItemProps {
  termIndex: number;
  valueIndex: number;
  modifier: string;
  added: boolean;
  include: string;
  valueToRender: string;
  dimension: string;
}
function TermItem({
  termIndex,
  valueIndex,
  modifier,
  added,
  include,
  valueToRender,
  dimension,
}: TermItemProps): JSX.Element {
  const { targetingValue, loading } = useGetTargetingValues(dimension, valueToRender);

  if (loading) {
    return <Loader />;
  }
  if (targetingValue?.displayName) {
    valueToRender = targetingValue.displayName;
  } else if (dimension !== 'age') {
    // omit age ranges. you can use these values directly
    // append _NOT_FOUND to all other items failing a display name lookup
    valueToRender = `${valueToRender}_NOT_FOUND`;
  }

  return (
    <li key={`${termIndex}-${valueIndex}`} className={element('term-list-item', modifier)}>
      {added && (
        <span>
          <img src={GreenPlus} alt="new-addition-plus-icon" className={element('plus-icon')} />
        </span>
      )}
      <span className={element('term-list-item-type')}>{include}</span>
      <span>{valueToRender}</span>
    </li>
  );
}

function TermList({ term, action = { removal: false, addition: false }, termIndex = 0 }: TermListProps): JSX.Element {
  const { removal, addition } = action;
  const valueSet = term?.valueSet || term?.['value-set'];

  return (
    <ul key={termIndex}>
      <span className={element('term-list-title')}>{titleCase(term?.dimension || '')}</span>
      {valueSet &&
        valueSet.map((value: ValueSet | string, valueIndex: number) => {
          const valueSet = typeof value === 'string' ? {} : value;
          const removed = valueSet?.status === TermStatus.REMOVED;
          const added = valueSet?.status === TermStatus.ADDED;
          const modifier = getModifier(removed || removal, added || addition);
          const include = getInclusion(valueSet?.include || term?.operation === 'INCLUDED' || !term?.not);
          const valueToRender = getValue(value);

          return valueToRender ? (
            <TermItem
              termIndex={termIndex}
              valueIndex={valueIndex}
              modifier={modifier}
              added={added}
              include={include}
              valueToRender={valueToRender}
              dimension={term?.dimension || ''}
            />
          ) : null;
        })}
    </ul>
  );
}

/**
 * Formats provided term list as an unordered list node
 */
export function TermsList({
  terms,
  action,
}: {
  terms: Term[] | Term;
  action: { removal: boolean; addition: boolean };
}): JSX.Element | null {
  const emptyTerms = !Array.isArray(terms) ? !terms?.dimension : terms.every((term: Term) => term?.dimension === null);
  if (emptyTerms) return null;

  if (!Array.isArray(terms)) return <TermList term={terms} action={action} />;

  return (
    <>
      {terms.map((term: Term, termIndex: number) => (
        <TermList term={term} termIndex={termIndex} action={action} key={`term-${termIndex}`} />
      ))}
    </>
  );
}

/**
 * Displays list of terms depending on data structure of changes prop
 */
export function TargetingCell({ changes, action }: CellItemsProps): JSX.Element {
  return (
    <div className={block()}>
      <div className={element('term-list')}>
        {Array.isArray(changes) ? (
          changes.map((change, index) => (
            <TermsList terms={change.payload as Term[] | Term} action={action} key={`targeting-${index}`} />
          ))
        ) : (
          <TermsList terms={changes.payload as Term[] | Term} action={action} />
        )}
      </div>
    </div>
  );
}

export default TargetingCell;
