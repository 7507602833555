import type { AxiosError, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import axios from 'axios';

import type { AssetType } from '../../configs';
import type { AssetV5 } from '../../models';
import campaignGraphqlManager from '../campaignGraphqlManager';
import type {
  CreateAssetErrorResponse,
  CreateAssetResponseData,
  CreateAssetSuccessResponse,
  UploadError,
} from './types';

export function makeFormAssetParams(
  file: File,
  assetType: AssetType,
  creativeLibraryId: string,
  countries: string[]
): FormData {
  const formData: FormData = new FormData();

  formData.append('type', assetType.toLowerCase());
  formData.append('name', file.name);
  formData.append('content', file);
  formData.append('country-codes', countries?.join(',') || '');
  creativeLibraryId && formData.append('creative-library-id', creativeLibraryId);

  return formData;
}

export function makeAssetModelFromResponse(fileSize: number, data: CreateAssetResponseData): AssetV5 {
  return {
    assetTagList: data.assetTagList || [],
    id: data.id,
    name: data.name,
    type: data.type,
    fileSize: fileSize || data['file-size'],
    review: data?.review,
    createdAt: new Date(data['created-at']),
  };
}

function makeErrorInfoFromResponse(error: AxiosError<CreateAssetErrorResponse>): UploadError {
  if (axios.isCancel(error)) {
    return { type: 'cancel' };
  }

  return {
    type: 'API',
    apiStatusCode: error.response?.status,
  };
}

export async function uploadAsset(
  file: File,
  assetType: AssetType,
  setPercentUploaded: (percent: number) => void,
  cancelTokenSource: CancelTokenSource,
  creativeLibraryId: string,
  countries: string[]
): Promise<AssetV5> {
  const formData: FormData = makeFormAssetParams(file, assetType, creativeLibraryId, countries);

  const axiosRequestConfig: AxiosRequestConfig = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    cancelToken: cancelTokenSource.token,
    onUploadProgress: (event: ProgressEvent) => setPercentUploaded((100 * event.loaded) / event.total),
  };

  return campaignGraphqlManager
    .post(`api/asset`, formData, axiosRequestConfig)
    .then((response: AxiosResponse<CreateAssetSuccessResponse>) =>
      makeAssetModelFromResponse(file.size, response.data.data)
    )
    .catch((error: AxiosError<CreateAssetErrorResponse>) => Promise.reject(makeErrorInfoFromResponse(error)));
}
