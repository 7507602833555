import type { MutationFunction } from '@apollo/client';

import type {
  AssetInputV5,
  CreateAdsPageAdMutation,
  CreateAdsPageAdMutationVariables,
  CreateAdsPageAdWithAssetMutation,
  CreateAdsPageAdWithAssetMutationVariables,
} from '../../../../../apis/graphql';
import type { AdFormValues } from '../../../../../common/AdForm/adFormik';
import { constructCreateAdInput } from './constructCreateAdInput';

type Mutations = {
  createAd: MutationFunction<CreateAdsPageAdMutation>;
  createAdWithAsset: MutationFunction<CreateAdsPageAdWithAssetMutation>;
};

type WithoutAsset = { mutation: Mutations['createAd']; variables: CreateAdsPageAdMutationVariables };
type WithAsset = { mutation: Mutations['createAdWithAsset']; variables: CreateAdsPageAdWithAssetMutationVariables };

export function getCreateMutation(
  mutations: Mutations,
  adFormValues: AdFormValues,
  lineItemId: string,
  assetInput?: AssetInputV5
): WithoutAsset | WithAsset {
  // Does the Ad have a valid Asset for mutation?
  if (!!assetInput && adFormValues.creative.type === 'VIDEO' && !!adFormValues.creative.video?.id) {
    const withAsset: WithAsset = {
      mutation: mutations.createAdWithAsset,
      variables: {
        adInput: constructCreateAdInput(adFormValues, lineItemId),
        assetId: adFormValues.creative.video?.id || '',
        assetInput,
      },
    };
    return withAsset;
  } else {
    const withoutAsset: WithoutAsset = {
      mutation: mutations.createAd,
      variables: { adInput: constructCreateAdInput(adFormValues, lineItemId) },
    };
    return withoutAsset;
  }
}
