import { addMonths, addWeeks, endOfMonth, subMonths } from 'date-fns';

import type { ScheduleFilterInputV5 } from '../../../../apis/graphql';
import { ScheduleFilterStatusV5 } from '../../../../apis/graphql';
import type { DropdownOptionGroup } from '../../../../common/Dropdown';
import type { DateType } from './DateFilter';

export const ALL_TIME_DATE_KEY = 'All Time';
export const CUSTOM_DATE = 'Custom Date';

const formatDateValue = (start: number[], end: number[], status: ScheduleFilterStatusV5): ScheduleFilterInputV5 => ({
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  start: { year: start[0], month: start[1], day: start[2], hour: start[3] },
  end: { year: end[0], month: end[1], day: end[2], hour: end[3] },
  status,
});

function makeGroupedDateOptions(date: Date = new Date()): DropdownOptionGroup<DateType>[] {
  const currentMonth = date.getMonth() + 1;
  const currentYear = date.getFullYear();
  const daysCurrentInMonth = endOfMonth(date).getDate();

  const nextMonthFullDate = addMonths(date, 1);
  const nextMonthNumerical = nextMonthFullDate.getMonth() + 1;
  const nextMonthEndDay = endOfMonth(nextMonthFullDate).getDate();
  const lastMonthFullDate = subMonths(date, 1);
  const lastMonthNumerical = lastMonthFullDate.getMonth() + 1;
  const lastMonthEndDay = endOfMonth(lastMonthFullDate).getDate();
  const lastMonthYear = lastMonthFullDate.getFullYear();
  const nextMonthYear = nextMonthFullDate.getFullYear();

  const currentHour = date.getHours();
  const currentDay = date.getDate();
  const nextWeekDate = addWeeks(date, 1);
  const nextWeekDay = nextWeekDate.getDate();
  const nextWeekMonth = nextWeekDate.getMonth() + 1;
  const nextWeekYear = nextWeekDate.getFullYear();

  // Common start and end dates for the current month
  const [start, end] = [
    [currentYear, currentMonth, 1, 1],
    [currentYear, currentMonth, daysCurrentInMonth, 23],
  ];

  const runningThisMonth = formatDateValue(start, end, ScheduleFilterStatusV5.Running);
  const endingThisMonth = formatDateValue(start, end, ScheduleFilterStatusV5.Ending);
  const nextMonth = formatDateValue(
    [nextMonthYear, nextMonthNumerical, 1, 1],
    [nextMonthYear, nextMonthNumerical, nextMonthEndDay, 23],
    ScheduleFilterStatusV5.Starting
  );
  const lastMonth = formatDateValue(
    [lastMonthYear, lastMonthNumerical, 1, 1],
    [lastMonthYear, lastMonthNumerical, lastMonthEndDay, 23],
    ScheduleFilterStatusV5.Ending
  );

  const nextWeekStartDate = [currentYear, currentMonth, currentDay, currentHour];
  const nextWeekEndDate = [nextWeekYear, nextWeekMonth, nextWeekDay, 23];

  const startingNextWeek = formatDateValue(nextWeekStartDate, nextWeekEndDate, ScheduleFilterStatusV5.Starting);
  const endingNextWeek = formatDateValue(nextWeekStartDate, nextWeekEndDate, ScheduleFilterStatusV5.Ending);

  return [
    {
      label: 'all time',
      options: [
        { value: ALL_TIME_DATE_KEY, label: ALL_TIME_DATE_KEY },
        { value: CUSTOM_DATE, label: CUSTOM_DATE },
      ],
    },
    {
      label: 'month',
      options: [
        { value: runningThisMonth, label: 'Running This Month' },
        { value: endingThisMonth, label: 'Ending This Month' },
        { value: nextMonth, label: 'Starting Next Month' },
        { value: lastMonth, label: 'Ended Last Month' },
      ],
    },
    {
      label: 'week',
      options: [
        { value: startingNextWeek, label: 'Starting Next 7 Days' },
        { value: endingNextWeek, label: 'Ending Next 7 Days' },
      ],
    },
  ];
}

export default makeGroupedDateOptions;
