import { User } from 'mission-control-common-components';
import React, { memo, useEffect } from 'react';
import type { Cell } from 'react-table';

import EditableDropdown from '../../../../../common/EditableTableCells/EditableDropdown';
import { openToastAlert } from '../../../../../common/ToastAlert/toastAlert';
import type { AdStatus, DisplayEnum } from '../../../../../configs';
import {
  adStatuses,
  convertConfigToDropdownOption,
  convertConfigToDropDownOptions,
  STATUS_ERROR,
} from '../../../../../configs';
import type { PermissionsNames } from '../../../../../constants';
import { ERROR_PATTERNS } from '../../../../../constants';
import { cellPropsComparison } from '../../../../../utils/common';
import { getIdFromTableCell } from '../../columns';
import { useUpdateAd } from '../../hooks';
import type { AdModel } from '../../modelConverters';

type Props = Cell<AdModel, DisplayEnum<AdStatus>>;

function AdStatusCell(cell: Props): JSX.Element {
  const permissions = User.getPermissions<PermissionsNames>();
  const id = getIdFromTableCell(cell);
  const { value } = cell;
  const { cellVariant, updateAdFields, errors } = useUpdateAd<'status'>(id);

  useEffect(() => {
    const error = errors?.find((errorMsg) => errorMsg.includes(ERROR_PATTERNS.STATUS_ACTIVATION_ERROR));

    if (error) {
      const isCampaignReviewError =
        error.includes(`${ERROR_PATTERNS.STATUS_ACTIVATION_ERROR}`) && error.toLowerCase().includes('approved status');
      openToastAlert({
        alertType: 'error',
        message: STATUS_ERROR.ad.message,
        description: isCampaignReviewError ? STATUS_ERROR.ad.description : error,
        duration: 4,
      });
    }
  }, [errors]);

  const onSubmit = (status: AdStatus): void => updateAdFields({ status });

  return (
    <EditableDropdown<AdStatus>
      classNameModifier="status-cell"
      selectedValue={convertConfigToDropdownOption<AdStatus>(value)}
      onSubmit={onSubmit}
      options={convertConfigToDropDownOptions(adStatuses)}
      variant={cellVariant}
      isDisabled={!permissions?.updateAd}
    />
  );
}

export default memo(AdStatusCell, cellPropsComparison);
