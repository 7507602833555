import { orderBy as _orderBy } from 'lodash';

import { SortState } from '../../SortArrow/constants';
import type { TargetingTableRowGroup } from '../TargetingTable';

export const sortTargetingTableRowGroups = (
  targetingTableRowGroups: TargetingTableRowGroup[],
  sortState: SortState
): TargetingTableRowGroup[] => {
  const sortTargetingTableRowGroupByAscOrDesc = (
    sortStateAscOrDesc: SortState.SORTED_ASC | SortState.SORTED_DESC
  ): TargetingTableRowGroup[] => {
    const sortedGroups = _orderBy(
      targetingTableRowGroups,
      ['groupType'],
      [sortStateAscOrDesc === SortState.SORTED_ASC ? 'asc' : 'desc']
    ).map((rowGroup) => ({
      ...rowGroup,
      targetingTermValues: _orderBy(
        rowGroup.targetingTermValues,
        ['category.displayName'],
        [sortStateAscOrDesc === SortState.SORTED_ASC ? 'asc' : 'desc']
      ),
    }));

    return sortedGroups;
  };

  const sortTargetingTableRowGroupsMap = {
    [SortState.SORTED_ASC]: sortTargetingTableRowGroupByAscOrDesc(SortState.SORTED_ASC),
    [SortState.SORTED_DESC]: sortTargetingTableRowGroupByAscOrDesc(SortState.SORTED_DESC),
    [SortState.UNSORTED]: targetingTableRowGroups,
  };

  return sortTargetingTableRowGroupsMap[sortState];
};
