import { isEqual as _isEqual, omit as _omit } from 'lodash';

import type { AdTypeV5 } from '../../../apis/graphql';
import { CreativeV5Type, removeTypeNamesFromObject } from '../../../apis/graphql';
import type { CreativeType } from '../../../configs';
import { adStatuses, UNRECOGNIZED } from '../../../configs';
import { permittedCreativeTypes } from '../../../configs';
import type { ClearableFrequencyCap } from '../../FrequencyCaps/FrequencyCaps';
import { filterTermValues } from '../../utils';
import type { CreativesBuilderValues } from '../CreativeBuilders/types';
import type { AdsPageAd } from '../hooks/types';
import type { AdFormValues } from '.';
import { getInitialCreativeBuilderValues, getInitialIASCampaignLineItemId } from '.';
import { AdFormFields } from './fields';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SetFieldValueFunction = (field: string, value: any, shouldValidate?: boolean | undefined) => void;

export const getCreativeTypeFromForm = (values: AdFormValues): CreativeType => {
  const { type } = values;

  return permittedCreativeTypes[type].default.key;
};

/**
 * The creative builder is "empty" if the actual data fields (excluding "mode") are identical to the initial values
 */
export const isCreativeBuilderEmpty = (creativeBuilderValues: CreativesBuilderValues, adType: AdTypeV5): boolean => {
  const excludedFields = ['mode', 'altText', 'creativeLibraryId'];

  return _isEqual(
    _omit(creativeBuilderValues, excludedFields),
    _omit(getInitialCreativeBuilderValues('selectOption', adType), excludedFields)
  );
};

export const resetCreativeBuilder = (
  adType: AdTypeV5,
  setFieldValue: SetFieldValueFunction,
  brand?: string,
  creativeLibraryId?: string
): void => {
  setFieldValue(
    AdFormFields.Creative,
    getInitialCreativeBuilderValues('selectOption', adType, brand, creativeLibraryId)
  );
};

export function adTypeToCreativeType(adType: AdTypeV5): CreativeV5Type {
  switch (adType) {
    case 'AD_SELECTOR':
      return CreativeV5Type.Brightline;
    case 'AD_SELECTOR_SLATE':
      return CreativeV5Type.AdSelectorSlate;
    case 'AD_SELECTOR_VIDEO':
      return CreativeV5Type.AdSelectorVideo;
    case 'MAX_SELECTOR':
      return CreativeV5Type.Brightline;
    case 'BRIGHTLINE':
      return CreativeV5Type.Brightline;
    case 'COLLECTION_LOGO':
      return CreativeV5Type.Display;
    case 'COVER_STORY_LOGO':
      return CreativeV5Type.Display;
    case 'HUB_LOGO':
      return CreativeV5Type.Display;
    case 'INNOVID':
      return CreativeV5Type.Innovid;
    case 'GATEWAY_GO':
      return CreativeV5Type.GatewayGo;
    case 'GATEWAY_SHOP':
      return CreativeV5Type.Brightline;
    case 'PAUSE':
      return CreativeV5Type.Pause;
    case 'PAUSE_QR':
      return CreativeV5Type.Pause;
    case 'SLATE':
      return CreativeV5Type.Slate;
    case 'VIDEO':
      return CreativeV5Type.Video;
    case 'MARQUEE_PROMOTED_CONTENT':
      return CreativeV5Type.MarqueePromotedContent;
    default:
      return CreativeV5Type.Video;
  }
}

/**
 * Get Create Ads Form values for existing Ads
 */
export const convertAdsPageAdToFormValues = (initialValues: AdFormValues, ad: AdsPageAd): AdFormValues => {
  const formValues: AdFormValues = {
    ...initialValues,
    id: ad.id,
    name: ad.name,
    deliveryStatus: ad.status === adStatuses.ON,
    frequencyCapList: ad.frequencyCapList.map(
      ({ limit, duration, enabled, type, durationUnit }): ClearableFrequencyCap => {
        return { limit, duration, enabled, type, durationUnit };
      }
    ),
    viewabilityVendorList: ad.viewabilityVendorList,
    adTagList: ad.adTagList,
    targetingTermValues: filterTermValues(ad.targetingTermValues),
    actualStart: ad.actualStart,
    schedule: ad.schedule!,
    status: ad.status,
    iasCampaignLineItemId: getInitialIASCampaignLineItemId(ad.adTagList),
    lineItem: { id: ad.lineItem.id, parentLineOmsLink: ad.lineItem.parentLineOmsLink },
  };

  if (ad.targetingRule) {
    formValues.targetingRule = removeTypeNamesFromObject(ad.targetingRule);
  }

  if (ad.type.key !== UNRECOGNIZED) {
    formValues.type = ad.type.key;
  }

  if (ad.rating.key !== UNRECOGNIZED) {
    formValues.rating = ad.rating.key;
  }

  if (ad.creative) {
    const isSubmitted = !!(ad.creative.id && ad.creative.url);

    formValues.creative = {
      ...ad.creative,
      isSubmitted,
    };
  }

  return {
    ...formValues,
  };
};
