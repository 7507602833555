import { useQuery } from '@apollo/client';

import type { Query, TargetingValue } from '../../../../../../apis/graphql';
import { GET_TARGETING_VALUE_BY_DIMENSION_AND_VALUE_ID } from '../../../../../../pages/Ads/hooks/queries';

type UseGetTargetingValuesResult = {
  targetingValue: TargetingValue | null;
  loading: boolean;
};

export const useGetTargetingValues = (dimensionGuid: string, valueId: string): UseGetTargetingValuesResult => {
  const { data, loading } = useQuery<Query>(GET_TARGETING_VALUE_BY_DIMENSION_AND_VALUE_ID, {
    variables: { dimensionGuid, valueId },
  });
  // the backend returns an array with a single value.
  const value = data?.getTargetingValuesByDimensionAndValueID[0];
  return { targetingValue: value || null, loading };
};

export default useGetTargetingValues;
