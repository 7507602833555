import type { MutationUpdaterFn } from '@apollo/client';
import { useMutation } from '@apollo/client';
import type { ExecutionResult } from 'graphql';
import { useCallback } from 'react';

import type {
  CreateAdsPageAdMutation,
  CreateAdsPageAdWithAssetMutation,
  TargetingRuleInput,
  UpdateAdsPageAdMutation,
  UpdateAdsPageWithAssetMutation,
} from '../../../../apis/graphql';
import { AdStatusV5, getErrorMessageFromGraphQlErrors } from '../../../../apis/graphql';
import type { AssetExtended } from '../../../../common/_ForCommonLibrary/types/extended';
import type { AdFormValues } from '../../../../common/AdForm/adFormik';
import type { AdsPageAd } from '../../../../common/AdForm/hooks/types';
import type { AdsPageLineItem } from '../../../../common/AdForm/types';
import { AlertType } from '../../../../common/Alert';
import { openToastAlert } from '../../../../common/ToastAlert/toastAlert';
import { WARNING_ALERT_DESCRIPTIONS, WARNING_ALERT_MESSAGES } from '../../../../constants';
import type { Nullable } from '../../../../models';
import {
  CREATE_ADS_PAGE_AD,
  CREATE_ADS_PAGE_AD_WITH_ASSET,
  UPDATE_ADS_PAGE_AD,
  UPDATE_ADS_PAGE_AD_WITH_ASSET,
} from '../mutations';
import type { SaveAdReturnType } from '../types';
import { buildAssetInput } from '../util/buildAssetInput';
import { getCreateMutation, getUpdateMutation, updateAdInCache, writeAdToCache } from './util';

type UseSaveAdResult = (
  values: AdFormValues,
  creativeId: Nullable<string>,
  assets?: AssetExtended[]
) => Promise<SaveAdReturnType>;

export function useSaveAd(
  lineItem: AdsPageLineItem,
  ads: AdsPageAd[],
  targetingRule?: TargetingRuleInput | null
): UseSaveAdResult {
  const [createAd] = useMutation<CreateAdsPageAdMutation>(CREATE_ADS_PAGE_AD);
  const [createAdWithAsset] = useMutation<CreateAdsPageAdWithAssetMutation>(CREATE_ADS_PAGE_AD_WITH_ASSET);
  const [updateAd] = useMutation<UpdateAdsPageAdMutation>(UPDATE_ADS_PAGE_AD);
  const [updateAdWithAsset] = useMutation<UpdateAdsPageWithAssetMutation>(UPDATE_ADS_PAGE_AD_WITH_ASSET);

  const saveAd = useCallback(
    // The "values" object is a snapshot of form state.
    // The snapshot is taken before the creativeId value is updated in the form's submit handler, so we rely on passing the creativeId to this function as an argument.
    // See this answer from the Formik author for more information: https://github.com/formium/formik/issues/839#issuecomment-460702644
    async (values: AdFormValues, creativeId: Nullable<string>, assets?: AssetExtended[]) => {
      if (!values.creative.id) values.creative.id = creativeId || '';

      const assetInput = buildAssetInput(lineItem, assets);

      // Ad exists (has been created)
      if (!!values?.id) {
        const { mutation, variables } = getUpdateMutation(
          { updateAd, updateAdWithAsset },
          values,
          assetInput,
          targetingRule
        );

        const { errors, data }: ExecutionResult<UpdateAdsPageAdMutation> = await mutation({
          variables,
          update: ((_, { data }) => updateAdInCache(data, values.id)) as MutationUpdaterFn<UpdateAdsPageAdMutation>,
        });

        if (errors) throw Error(getErrorMessageFromGraphQlErrors(errors));
        if (!data || !data.updateAd) throw Error('Ad save failed');

        if (
          !!values.deliveryStatus &&
          values.adTagList.some((el) => !el.isVerified) &&
          data?.updateAd?.status === AdStatusV5.Off
        ) {
          openToastAlert({
            alertType: AlertType.WARNING,
            message: WARNING_ALERT_MESSAGES.ACTIONS_REQUIRED,
            description: WARNING_ALERT_DESCRIPTIONS.STATUS_REVERTED,
            duration: 10,
          });
        }

        return { createdAdTagList: data.updateAd.adTagList };
        // Ad is updated in apollo cache - no need to refetch ad list on update
      } else {
        // We want to:
        // 1. Create the ad
        // 2. Read the existing ad list from the cache
        // 3. Write our new ad into the cache, without refetching the entire list.
        const { mutation, variables } = getCreateMutation(
          { createAd, createAdWithAsset },
          values,
          lineItem.id,
          assetInput
        );

        const { errors, data }: ExecutionResult<CreateAdsPageAdMutation> = await mutation({ variables });

        if (errors) throw Error(getErrorMessageFromGraphQlErrors(errors));
        if (!data || !data.createAd) throw Error('Ad creation failed');

        writeAdToCache(lineItem.id, values, data);

        return { createdAdId: data.createAd.id, createdAdTagList: data.createAd.adTagList };
      }
    },
    [createAd, createAdWithAsset, updateAd, updateAdWithAsset, lineItem]
  );

  return saveAd;
}
