import { PathName, PermissionsNames } from '../constants';

/**
 * we need separate configuration for the PricingManager component to handle router.
 * More information: https://github.prod.hulu.com/AdPlatformV2/mission-control-ui/pull/1584
 * And revert commit: https://github.prod.hulu.com/AdPlatformV2/mission-control-ui/commit/88b29dc1916b2ded0b6d0569fba869db9115da08
 */
export const pricingManagerConfig = {
  name: 'pricing-manager-ui-plugin',
  route: PathName.pricing,
  permissionName: PermissionsNames.pricingPageRead,
  getComponent: () => import('pricing-manager-ui-plugin'),
};

export const supplyManagerConfig = {
  name: 'supply-management-ui-plugin',
  route: PathName.supplyManagement,
  permissionName: PermissionsNames.supplyManagementPageRead,
  getComponent: () => import('supply-management-ui-plugin'),
};

export const demandManagerConfig = {
  name: 'demand-management-ui-plugin',
  route: PathName.demandManagement,
  permissionName: PermissionsNames.demandManagementPageRead,
  getComponent: () => import('demand-management-ui-plugin'),
};

const configuration = [
  {
    name: 'campaign-review-ui-plugin',
    route: PathName.campaignReview,
    permissionName: PermissionsNames.campaignReviewPageRead,
    getComponent: () => import('campaign-review-ui-plugin'),
  },
  {
    name: 'asset-review-ui-plugin',
    route: PathName.assetReview,
    permissionName: PermissionsNames.assetReviewPageRead,
    getComponent: () => import('asset-review-ui-plugin'),
  },
  {
    name: 'inventory-manager-ui-plugin',
    route: PathName.inventory,
    permissionName: PermissionsNames.inventoryPageRead,
    getComponent: () => import('inventory-manager-ui-plugin'),
  },
  {
    name: 'access-manager-ui-plugin',
    route: PathName.accessManager,
    permissionName: PermissionsNames.accessManagerPageRead,
    getComponent: () => import('access-manager-ui-plugin'),
  },
  {
    name: 'targeting-preset-ui-plugin',
    route: PathName.targetingPreset,
    permissionName: PermissionsNames.presetPageRead,
    getComponent: () => import('targeting-preset-ui-plugin'),
  },
  {
    name: 'inventory-block-ui-plugin',
    route: PathName.inventoryBlock,
    // temp usage of preset permission until inventory-block-ui have its own
    permissionName: PermissionsNames.readInventoryBlock,
    getComponent: () => import('inventory-block-ui-plugin'),
  },
];
export default configuration;
