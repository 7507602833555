import type { ApolloError } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';

import type {
  LineItemTraffickingColumnsFragment,
  Maybe,
  UpdateLineItemInputV5,
  UpdateLineItemMutation,
  UpdateLineItemMutationVariables,
} from '../../../../apis/graphql';
import { campaignClient } from '../../../../apis/graphql';
import type { EditableInputVariant } from '../../../../common/EditableTableCells/EditableCell/EditableCell';
import { LINE_ITEM_TRAFFICKING_COLUMNS } from './fragments';
import { UPDATE_TRAFFICKING_PAGE_LINE_ITEM } from './mutations';
import useCellVariant from './useCellVariant';
import { onHandleLineItemUpdateErrors } from './utils';

type UseUpdateLineItem = {
  cellVariant: EditableInputVariant;
  updateLineItemField: (newValue: UpdateLineItemInputV5[keyof UpdateLineItemInputV5]) => void;
  errors: string[];
  loading: boolean;
  data?: Maybe<LineItemTraffickingColumnsFragment>;
};

const LINE_ITEM_UPDATE_FAILED_MESSAGE = 'Line Item Save Failed';

const useUpdateLineItem = (
  lineItemId: string,
  inputFieldName: keyof UpdateLineItemInputV5,
  onFailureUpdate?: ({ message, description }: Record<string, string>) => void
): UseUpdateLineItem => {
  const [graphQLErrors, setGraphQLErrors] = useState<string[]>([]);
  const [updateLineItem, mutationResult] = useMutation<UpdateLineItemMutation, UpdateLineItemMutationVariables>(
    UPDATE_TRAFFICKING_PAGE_LINE_ITEM
  );

  const cellVariant = useCellVariant(mutationResult);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const updateLineItemField = useCallback(
    async (newValue: UpdateLineItemInputV5[typeof inputFieldName]) => {
      // we need this data because after update we can't receive some fields from campaign-manager
      // according to bug AX-3785
      const oldCacheData = campaignClient.readFragment({
        id: `LineItemV5:${lineItemId}`,
        fragment: LINE_ITEM_TRAFFICKING_COLUMNS,
      });

      try {
        const data = await updateLineItem({
          variables: {
            id: lineItemId,
            updateLineItemInput: {
              [inputFieldName]: newValue,
            },
          },
          update(_, { data }) {
            if (!data || !data.updateLineItem) {
              return;
            }

            const {
              startDate,
              endDate,
              metrics,
              displayPublisherTarget,
              deliveryGoal,
              lineItemThreshold,
              pacingMetrics,
              country,
              magniteDealDto,
              orderLineItemDto,
              hasZeroDeliveryRisk,
            } = oldCacheData;

            const updatedData = {
              ...data?.updateLineItem,
              startDate,
              endDate,
              metrics,
              displayPublisherTarget,
              deliveryGoal,
              lineItemThreshold,
              pacingMetrics,
              country,
              magniteDealDto,
              orderLineItemDto,
              hasZeroDeliveryRisk,
            };

            campaignClient.writeFragment({
              fragment: LINE_ITEM_TRAFFICKING_COLUMNS,
              data: updatedData,
              variables: {
                id: lineItemId,
                updateLineItemInput: {
                  [inputFieldName]: newValue,
                },
              },
            });
          },
        });

        if (data && data.errors?.length) {
          onHandleLineItemUpdateErrors({
            message: LINE_ITEM_UPDATE_FAILED_MESSAGE,
            onSetGraphQLErrors: setGraphQLErrors,
            errors: data.errors,
            onFailureUpdate: onFailureUpdate,
          });
        } else {
          setGraphQLErrors([]);
        }
      } catch (error) {
        onHandleLineItemUpdateErrors({
          message: LINE_ITEM_UPDATE_FAILED_MESSAGE,
          onSetGraphQLErrors: setGraphQLErrors,
          error: error as ApolloError,
          onFailureUpdate: onFailureUpdate,
        });
      }
    },
    [inputFieldName, lineItemId, onFailureUpdate, updateLineItem]
  );

  return {
    cellVariant,
    updateLineItemField,
    errors: graphQLErrors,
    loading: mutationResult.loading,
    data: mutationResult.data?.updateLineItem,
  };
};

export default useUpdateLineItem;
