import './MultipleEditDrawerContentThirdStep.scss';

import type { FetchResult } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { useFormikContext } from 'formik';
import type { GraphQLError } from 'graphql';
import React, { useCallback, useMemo } from 'react';

import type { MultiEditAdsMutation, MultiEditLineItemMutation } from '../../../../../../../apis/graphql';
import Loader from '../../../../../../../common/Loader';
import bem from '../../../../../../../utils/bem';
import { EntityTypeArray } from '../../../../../TraffickingPage/hooks/makePageDrawerPlugin';
import type { LineItemModel } from '../../../../../TraffickingPage/modelConverters';
import type {
  AdEntity,
  LineItemEntity,
  MultipleEditDrawerAdsFormValues,
  MultipleEditDrawerFormValues,
  MultipleEditDrawerLineItemsFormValues,
} from '../../../types';
import Status from '../MultipleEditDrawerContentSecondStep/cardsContent/Status';
import { Error } from './components/Error/Error';
import { Header } from './components/Header/Header';
import { Row } from './components/Row/Row';
import { UPDATE_ADS, UPDATE_LINE_ITEMS } from './queries';
import {
  getConflictIdsWithoutDuplicates,
  getOldCacheData,
  onHandleUpdateError,
  onHandleUpdateSuccess,
  updateCacheData,
} from './utils';
import { useGetAllConflicts } from './utils/checkConflicts/getAllConflicts/useGetAllConflicts';
import { getInputForUpdateAds } from './utils/getPreparedInput/getInputForUpdate/getInputForUpdateAds';
import { getInputForUpdateLineItems } from './utils/getPreparedInput/getInputForUpdate/getInputForUpdateLineItems';
import { getConflictComponents } from './utils/getRows/conflicts';
import { getAdsRows } from './utils/getRows/getAdsRows';
import { getLineItemsRows } from './utils/getRows/getLineItemsRows';
import { useGetNormalizedTargeting } from './utils/useGetNormalizedTargeting/useGetNormalizedTargeting';
import { getTargetingValues } from './utils/utils';

const [block, element] = bem('multiple-edit-drawer-content-third-step');

type MultipleEditDrawerContentThirdStepProps = {
  onSubmit: () => void;
  entityType: EntityTypeArray;
};

export type LineItemStatusError = {
  lineItemsWithoutApproved?: Array<LineItemModel['id']>;
  lineItemsWithoutActiveAd?: Array<LineItemModel['id']>;
};

export default function MultipleEditDrawerContentThirdStep({
  onSubmit,
  entityType,
}: MultipleEditDrawerContentThirdStepProps): React.JSX.Element {
  const isLineItem = entityType === EntityTypeArray.LINE_ITEM_ARRAY;
  const [updateEntities, { loading }] = useMutation(isLineItem ? UPDATE_LINE_ITEMS : UPDATE_ADS);
  const { values } = useFormikContext<MultipleEditDrawerLineItemsFormValues | MultipleEditDrawerAdsFormValues>();

  const valuesWithSelectedEntities: MultipleEditDrawerFormValues = useMemo(() => {
    const resultValues = { ...values };
    resultValues.entities = resultValues.entities.filter(({ isSelected }) => isSelected);

    return resultValues;
  }, [values]);

  const selectedEntitiesIds = useMemo(
    () => valuesWithSelectedEntities.entities.map((entity: LineItemEntity | AdEntity) => entity.id),
    [valuesWithSelectedEntities.entities]
  );

  const {
    lineItemConflicts,
    targetingConflicts,
    bufferConflicts,
    priorityConflicts,
    lineItemStatusErrors,
    isTargetingErrorsLoading,
    isLineItemStatusErrorLoading,
    schedulingConflicts,
    tagsConflicts,
    adsStatusErrors,
  } = useGetAllConflicts({
    values: valuesWithSelectedEntities,
    isLineItem,
  });

  const existedTargeting = useGetNormalizedTargeting({
    isLineItem,
    values: valuesWithSelectedEntities,
  });
  const targeting = getTargetingValues({ formTargeting: values.targetingTermValues });

  const schedulingConflictsIds: string[] = useMemo(() => getConflictIdsWithoutDuplicates(schedulingConflicts), [
    schedulingConflicts,
  ]);
  const tagsConflictsIds: string[] = useMemo(() => getConflictIdsWithoutDuplicates(tagsConflicts), [tagsConflicts]);

  const oldCacheData = useMemo(() => getOldCacheData(isLineItem, selectedEntitiesIds), [
    isLineItem,
    selectedEntitiesIds,
  ]);

  const onConfirm = useCallback(async (): Promise<void> => {
    const { hasInputForUpdate, resultInput } = isLineItem
      ? getInputForUpdateLineItems({
          lineItemStatusErrors,
          values: valuesWithSelectedEntities as MultipleEditDrawerLineItemsFormValues,
          bufferConflicts,
          targetingConflicts,
          priorityConflicts,
          isLineItem,
          existedTargeting: existedTargeting?.filterTargetingWithEntityId,
        })
      : getInputForUpdateAds({
          values: valuesWithSelectedEntities as MultipleEditDrawerAdsFormValues,
          schedulingConflictsIds,
          tagsConflictsIds,
          existedTargeting: existedTargeting?.filterTargetingWithEntityId,
          targetingConflicts,
          statusConflictIds: adsStatusErrors,
        });

    if (hasInputForUpdate) {
      const { errors } = await updateEntities({
        variables: {
          input: resultInput,
          targetingRule: existedTargeting?.targetingRule,
        },
        update(_, mutationResult: FetchResult<MultiEditLineItemMutation | MultiEditAdsMutation>) {
          const data = mutationResult.data as MultiEditLineItemMutation | MultiEditAdsMutation;

          updateCacheData(isLineItem, data, oldCacheData);
        },
      });

      if (errors) {
        const mutableErrors: GraphQLError[] = [...errors];
        onHandleUpdateError(isLineItem, mutableErrors);
      } else {
        onHandleUpdateSuccess(isLineItem, onSubmit);
      }
    }
  }, [
    adsStatusErrors,
    bufferConflicts,
    existedTargeting?.filterTargetingWithEntityId,
    isLineItem,
    lineItemStatusErrors,
    onSubmit,
    priorityConflicts,
    schedulingConflictsIds,
    tagsConflictsIds,
    targetingConflicts,
    updateEntities,
    valuesWithSelectedEntities,
    oldCacheData,
  ]);

  const rows = useMemo(
    () =>
      isLineItem
        ? getLineItemsRows({
            values: values as MultipleEditDrawerLineItemsFormValues,
            targeting,
            conflicts: {
              targeting: targetingConflicts,
              buffer: bufferConflicts,
              priority: priorityConflicts,
            },
            isTargetingErrorsLoading: isTargetingErrorsLoading && values.targetingTermValues.length > 0,
          })
        : getAdsRows({
            targeting,
            values: values as MultipleEditDrawerAdsFormValues,
            conflicts: { targeting: targetingConflicts, scheduling: schedulingConflicts, tags: tagsConflicts },
            isTargetingErrorsLoading,
          }),
    [
      isLineItem,
      values,
      targeting,
      targetingConflicts,
      bufferConflicts,
      priorityConflicts,
      isTargetingErrorsLoading,
      schedulingConflicts,
      tagsConflicts,
    ]
  );

  const isConfirmDisabled = useMemo((): boolean => {
    return !!isTargetingErrorsLoading || (!!isLineItemStatusErrorLoading && isLineItem) || loading;
  }, [isLineItem, isLineItemStatusErrorLoading, isTargetingErrorsLoading, loading]);

  return (
    <div className={block()}>
      <Header onSubmit={onConfirm} isLoading={loading} isDisabled={isConfirmDisabled} />
      <div>
        {rows.map((row, index) => (
          <Row label={row.label} value={row.value} key={index} conflicts={row.conflicts} isLoading={row.isLoading} />
        ))}
        <div className={element('status-table-wrapper')}>
          <Status isExpanded={false} entityType={EntityTypeArray.LINE_ITEM_ARRAY} readonly={true} />

          <div>
            {isLineItem && isLineItemStatusErrorLoading ? (
              <Loader />
            ) : (
              <>
                {lineItemConflicts?.errorUnapproved && <Error message={lineItemConflicts.errorUnapproved} />}
                {lineItemConflicts?.errorInactiveAd && <Error message={lineItemConflicts.errorInactiveAd} />}
              </>
            )}
            {!isLineItem && adsStatusErrors && (
              <>
                {getConflictComponents(
                  Object.keys(adsStatusErrors).map((conflictKey) => ({
                    message: conflictKey,
                    conflictIds: adsStatusErrors[conflictKey],
                    isShown: !!adsStatusErrors,
                  }))
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
