import type { ApolloError } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import type { GetAdsPageAdByIdQuery, GetAdsPageAdByIdQueryVariables } from '../../../../../../apis/graphql';
import { getDataFromNodes } from '../../../../../../apis/graphql';
import { adsPageAdNodeToModel } from '../../../../../../common/AdForm/hooks/modelConverters';
import type { AdsPageAd } from '../../../../../../common/AdForm/hooks/types';
import { filterTermValues } from '../../../../../../common/utils';
import type { Nullable, TargetingTermValue } from '../../../../../../models';
import { GET_ADS_PAGE_DATA_BY_IDS } from '../queries/getAdsPageAdByID';

type AdFetchResponse = {
  ad: Nullable<AdsPageAd>;
  lineItemTargetingTermValues: TargetingTermValue[];
  loading: boolean;
};

const useFetchDuplicateAd = (adId: string, lineItemId: string, onError: (e: ApolloError) => void): AdFetchResponse => {
  const { data, loading } = useQuery<GetAdsPageAdByIdQuery, GetAdsPageAdByIdQueryVariables>(GET_ADS_PAGE_DATA_BY_IDS, {
    variables: { adId, lineItemId },
    fetchPolicy: 'cache-and-network',
    onError,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const ads = useMemo<AdsPageAd[]>(getDataFromNodes(data, 'ads', adsPageAdNodeToModel), [data]);

  const lineItemTargetingTermValues = useMemo<TargetingTermValue[]>(
    () => filterTermValues(data?.getTargetingTermValuesByLineItemIDV5 as TargetingTermValue[]),
    [data]
  );

  const ad = ads.length ? ads[0] : null;

  return { ad, lineItemTargetingTermValues, loading };
};

export default useFetchDuplicateAd;
