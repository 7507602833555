/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { IconCalendar } from '@hulu-react-style-components/icons';
import React from 'react';
import type { Cell } from 'react-table';

import { resizingData, stickyBlock } from '../../../../common/TraffickingTable/utils';
import { COLUMN_NAME_ID, TraffickingTableName } from '../../../../constants';
import bem from '../../../../utils/bem';
import { fromEmailToName } from '../../../../utils/common';
import { DEFAULT_COLUMN_MIN_WIDTH } from '../../constants';
import { AlertCell, CampaignLeadCell } from '../cells';
import type { AlertFlagProps } from '../cells/AlertCell';
import { AlertFiled } from '../cells/AlertCell/AlertCell';
import CountryCell from '../cells/CountryCell';
import NameCell from '../cells/NameCell';
import { EntityType } from '../hooks/makePageDrawerPlugin';
import type { CampaignModel } from '../modelConverters';
import type { CustomColumnConfig } from './utils';
import { formatStartEndDateString, microCurrencyToDollarAmount, stringToDateTimeString } from './utils';

const [, element] = bem('alert-cell');

export const getCampaignAlertFlags = ({
  id,
  hasZeroDeliveryRisk,
  hasMisalignedSchedule,
}: CampaignModel): AlertFlagProps[] => {
  return [
    {
      id,
      field: AlertFiled.ZeroDelivery,
      isShown: !!hasZeroDeliveryRisk,
      hoverMessage:
        'This campaign contains 1+ Line Item(s) that have not delivered any impressions in the last 12 hours.',
    },
    {
      id,
      field: AlertFiled.MisalignedSchedule,
      isShown: !!hasMisalignedSchedule,
      icon: <IconCalendar width={18} height={18} />,
      hoverMessage: 'This campaign contains 1+ Line Item(s) with schedule conflict alert.',
      className: element('red-icon'),
    },
  ];
};

const getCampaignsColumns = (hasAccessToChangeLog?: boolean): CustomColumnConfig<CampaignModel>[] => [
  {
    id: 'Alert',
    Header: 'Alert',
    Cell: (cell: Cell<CampaignModel>) => <AlertCell flags={getCampaignAlertFlags(cell.row.original)} />,
    width: resizingData.getData(65, TraffickingTableName.campaigns, 'Alert'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: COLUMN_NAME_ID,
    accessor: 'name',
    Header: 'Campaign Name',
    width: resizingData.getData(350, TraffickingTableName.campaigns, 'Name'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    Cell: ({ row: { original }, openTraffickingPageDrawer }) => (
      <NameCell
        rowOriginal={original}
        entityType={EntityType.CAMPAIGN}
        openTraffickingPageDrawer={hasAccessToChangeLog ? openTraffickingPageDrawer : () => {}}
      />
    ),
    className: stickyBlock('name'),
    disableHiding: true,
  },
  {
    id: 'TrackingId',
    accessor: 'trackingId',
    Header: 'Tracking ID',
    width: resizingData.getData(175, TraffickingTableName.campaigns, 'TrackingId'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'brand',
    accessor: (c) => {
      return c.crmLink?.name;
    },
    Header: 'Brand',
    width: resizingData.getData(350, TraffickingTableName.campaigns, 'brand'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'Country',
    accessor: ({ lineItemList }) => lineItemList || [],
    Header: 'Country',
    width: resizingData.getData(190, TraffickingTableName.lineItems, 'Country'),
    Cell: (cell: Cell<CampaignModel>) => <CountryCell lineItemList={cell.value} />,
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'OrderType',
    accessor: (c) => c.orderType?.displayName,
    Header: 'Order Type',
    width: resizingData.getData(150, TraffickingTableName.campaigns, 'OrderType'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'OrderOmsLinkId',
    accessor: (c) => c.orderOmsLink?.id,
    Header: 'Operative Sales Order ID',
    width: resizingData.getData(250, TraffickingTableName.campaigns, 'OrderOmsLinkId'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'TraffickerEmail',
    accessor: 'traffickerEmail',
    Header: 'Account Manager',
    Cell: CampaignLeadCell,
    isEditable: true,
    width: resizingData.getData(200, TraffickingTableName.campaigns, 'TraffickerEmail'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'OrderSalespersonEmail',
    accessor: (campaign) => (campaign.salespersonEmail ? fromEmailToName(campaign.salespersonEmail) : ''),
    Header: 'Account Executive',
    width: resizingData.getData(200, TraffickingTableName.campaigns, 'OrderSalespersonEmail'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'Start',
    accessor: (c) => c.startDate,
    Header: 'Start Date',
    Cell: formatStartEndDateString,
    width: resizingData.getData(135, TraffickingTableName.campaigns, 'Start'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'End',
    accessor: (c) => c.endDate,
    Header: 'End Date',
    Cell: formatStartEndDateString,
    width: resizingData.getData(135, TraffickingTableName.campaigns, 'End'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'advertiser',
    accessor: (c) => c.advertiser,
    Header: 'Advertiser',
    width: resizingData.getData(330, TraffickingTableName.campaigns, 'advertiser'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'industryName',
    accessor: (c) => c.industryName,
    Header: 'Industry',
    width: resizingData.getData(330, TraffickingTableName.campaigns, 'industryName'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'agency',
    accessor: (c) => c.agency,
    Header: 'Agency',
    width: resizingData.getData(330, TraffickingTableName.campaigns, 'agency'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'campaignID',
    accessor: 'id',
    Header: 'Campaign ID',
    width: resizingData.getData(320, TraffickingTableName.campaigns, 'campaignID'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'CreatedAt',
    accessor: 'createdAt',
    Header: 'Created Date',
    Cell: stringToDateTimeString,
    width: resizingData.getData(190, TraffickingTableName.campaigns, 'CreatedAt'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'UpdatedAt',
    accessor: 'updatedAt',
    Header: 'Updated Date',
    Cell: stringToDateTimeString,
    width: resizingData.getData(175, TraffickingTableName.campaigns, 'UpdatedAt'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'adAccount',
    accessor: (c) => c.adAccount?.name,
    Header: 'Ad Account',
    width: resizingData.getData(320, TraffickingTableName.campaigns, 'adAccount'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'AdAccountId',
    accessor: (c) => c.adAccount?.id,
    Header: 'Ad Account Id',
    width: resizingData.getData(324, TraffickingTableName.campaigns, 'AdAccountId'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'BrandId',
    accessor: (c) => c.crmLink?.id,
    Header: 'Brand Id',
    width: resizingData.getData(200, TraffickingTableName.campaigns, 'BrandId'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
  },
  {
    id: 'campaignBudget',
    accessor: 'budget',
    Header: 'Campaign Budget',
    Cell: microCurrencyToDollarAmount,
    width: resizingData.getData(155, TraffickingTableName.campaigns, 'campaignBudget'),
    minWidth: DEFAULT_COLUMN_MIN_WIDTH,
    className: 'price-cell',
  },
];

export default getCampaignsColumns;
