import { gql } from '@apollo/client';

import { campaignClient, getErrorMessageFromGraphQlErrors } from '../../../../../apis/graphql';
import type { BaseAssetV5 } from '../../../../../apis/graphql/types';
import { CREATIVE_ASSET_FRAGMENT } from '../../../../../common/AdForm/hooks/fragments/creativeAssetFragment';

const GET_ASSETS_BY_ID = gql`
  query getAssetsById($assetIds: [String!]!) {
    getAssetsByIDsV5(assetIdList: $assetIds) {
      ...creativeAsset
    }
  }
  ${CREATIVE_ASSET_FRAGMENT}
`;

export async function fetchAssetsByIds(assetIds: string[]): Promise<BaseAssetV5[]> {
  if (!assetIds) return [];

  const { data, errors } = await campaignClient.query({
    query: GET_ASSETS_BY_ID,
    variables: { assetIds },
  });

  if (errors) throw new Error(getErrorMessageFromGraphQlErrors(errors));

  return data.getAssetsByIDsV5 || [];
}
