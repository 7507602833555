import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import type { Column, PluginHook, TableInstance, TableState } from 'react-table';
import { useTable } from 'react-table';

import type { AdEdgeV5, CampaignEdgeV5, LineItemEdgeV5, NodeTransformFunction } from '../../../../apis/graphql';
import { getDataFromNodes } from '../../../../apis/graphql';
import { hiddenData, orderData } from '../../../../common/TraffickingTable/utils';
import type { TraffickingTableName } from '../../../../constants';
import { useFiltersContext } from '../../../../contexts/FilterContext';
import type { ResultKeys } from '../../constants';
import { getSelectedRowIdsFromState } from '../../utils';
import type { EntityModel } from '../modelConverters';
import { prepareSortOptionsForTable } from './utils';

type Data = {
  [Property in ResultKeys]?: {
    edges: AdEdgeV5[] | LineItemEdgeV5[] | CampaignEdgeV5[];
    total: number;
    __typename?: AdEdgeV5['__typename'] | LineItemEdgeV5['__typename'] | CampaignEdgeV5['__typename'];
  };
};

type TableOptions = {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
};

const getRowId = (row: EntityModel): typeof row['id'] => row['id'];

function useTraffickerTableInstance<NodeType, Model extends EntityModel>(
  loadedData: Data,
  nodesKey: ResultKeys,
  transformFn: NodeTransformFunction<NodeType, Model>,
  columns: Column<Model>[],
  tableName: TraffickingTableName,
  options: TableOptions,
  plugins: PluginHook<Model>[]
): TableInstance<Model> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableData = useMemo<Model[]>(getDataFromNodes<NodeType, Model>(loadedData, nodesKey, transformFn), [
    loadedData,
  ]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const { filters } = useFiltersContext();

  const hiddenColumns = useMemo(() => hiddenData.getStorageData(tableName) || [], [tableName]);
  const columnOrder = useMemo(() => orderData.getStorageData(tableName) || [], [tableName]);
  const selectedRows = useMemo(() => getSelectedRowIdsFromState(filters[tableName]?.selectedRowIds || []), [
    filters,
    tableName,
  ]);

  const initialState: Partial<TableState<Model>> = {
    sortBy: prepareSortOptionsForTable(tableName, filters[tableName]?.sortBy),
    selectedRowIds: selectedRows,
    hiddenColumns,
    columnOrder,
  };

  return useTable<Model>(
    {
      columns: memoizedColumns,
      data: tableData,
      manualSortBy: true,
      getRowId,
      initialState,
      ...options,
    },
    ...plugins
  );
}

export default useTraffickerTableInstance;
