import type { MockedResponse } from '@apollo/client/testing';

import type {
  AdsPageAdFragment,
  GetCreativeApprovalsQuery,
  GetCreativeByIdQuery,
  ScheduleV5,
} from '../../apis/graphql';
import { ActiveDayTypeV5, CreativeV5Type } from '../../apis/graphql';
import {
  adStatuses,
  adTagEvents,
  adTagTypes,
  adTypes,
  billableThirdParties,
  frequencyCapUnitTypes,
  reviews,
  viewabilityVendors,
} from '../../configs';
import { defaultSupportedTimezones } from '../commonMocks/timezones';
import { AD_SEQUENCE_MOCK } from './../../pages/Sequence/utils/mocks';
import type { AdFormValues } from './adFormik';
import { getInitialValues } from './adFormik';
import { GET_CREATIVE_APPROVALS } from './CreativeBuilders/hooks/queries/getCreativeApprovals';
import type { CreativesBuilderValues } from './CreativeBuilders/types';
import type { AdsPageAd } from './hooks/types';

const initialValues: AdFormValues = {
  ...getInitialValues({ type: 'VIDEO' }),
};

export const mockAdFormTargetingTermValues = [
  {
    category: { id: 'country-id', name: 'Geo', displayName: 'Geo' },
    dimension: { id: 'country', name: 'Country', displayName: 'Country' },
    value: {
      id: 'US',
      name: 'United States',
      displayName: 'United States',
      description: 'United States',
    },
    include: true,
  },
];

export const withoutCreativeAdFromValues: AdFormValues = {
  ...initialValues,
  actualStart: '2022-11-11T23:18:24Z',
  adTagList: [
    {
      id: 'billable-tag-id',
      event: {
        displayName: 'Complete',
        key: 'COMPLETE',
      },
      isVerified: true,
      type: {
        displayName: 'Billable',
        key: 'BILLABLE',
      },
      unDeletable: false,
      url: 'https://www.hehehaha.com',
      isIas: null,
    },
  ],
  frequencyCapList: [
    {
      duration: 20,
      durationUnit: {
        displayName: 'Hours',
        key: 'HOUR',
      },
      enabled: false,
      limit: 10,
      type: 'AD',
    },
    {
      duration: 60,
      durationUnit: {
        displayName: 'Days',
        key: 'DAY',
      },
      enabled: false,
      limit: 1,
      type: 'AD',
    },
  ],
  id: 'test-ad-id',
  name: 'Duplicate ad mock',
  schedule: {
    dateRangeList: [
      {
        startDate: '2021-11-11T00:00:00',
        endDate: '2021-12-12T23:59:59',
        pacingShare: null,
      },
    ],
    dayPartList: [
      {
        activeDayList: [ActiveDayTypeV5.Monday, ActiveDayTypeV5.Friday],
        firstActiveHour: 19,
        lastActiveHour: 21,
      },
    ],
    deliveryInViewerTime: false,
    timezone: 'America/New_York',
  },
  targetingTermValues: mockAdFormTargetingTermValues,
  lineItem: {
    id: 'line-item-id',
    parentLineOmsLink: {
      id: 'parent-line-oms-link-id',
    },
  },
};

export const withCreativeAdFromValues: AdFormValues = {
  ...withoutCreativeAdFromValues,
  creative: {
    id: 'creative-id-VIDEO',
    name: 'Test Creative',
    review: 'PENDING',
    video: {
      id: 'video-id',
    },
    type: 'VIDEO',
    mode: 'selectOption',
    creativeLibraryId: '',
    isSubmitted: false,
  },
};

export const adsPageAdNodeMock: AdsPageAdFragment = {
  __typename: 'AdV5',
  actualStart: '2022-11-11T23:18:24Z',
  adRotation: {
    rotationId: 'rotationId',
    rotationName: 'test',
    weight: 12,
  },
  adTagList: [
    {
      id: 'billable-tag-id',
      event: 'COMPLETE',
      isVerified: true,
      type: 'BILLABLE',
      unDeletable: false,
      url: 'https://www.hehehaha.com',
      isIas: null,
    },
  ],
  createdAt: '2021-02-19T23:18:24Z',
  creative: null,
  frequencyCapList: [
    { type: 'AD', limit: 10, duration: 20, durationUnit: 'HOUR', enabled: null },
    { type: 'AD', limit: 1, duration: 60, durationUnit: 'DAY', enabled: null },
  ],
  id: 'test-ad-id',
  lineItem: {
    id: 'line-item-id',
    parentLineOmsLink: {
      id: 'parent-line-oms-link-id',
    },
    schedule: {
      dateRangeList: [
        {
          endDate: '2021-12-12T23:59:59',
          pacingShare: null,
          startDate: '2021-11-11T00:00:00',
        },
      ],
      dayPartList: [
        {
          activeDayList: [ActiveDayTypeV5.Monday, ActiveDayTypeV5.Friday],
          firstActiveHour: 19,
          lastActiveHour: 21,
        },
      ],
      deliveryInViewerTime: false,
      timezone: 'America/New_York',
    },
  },
  name: 'Duplicate ad mock',
  rating: 'CHILD_SAFE',
  review: 'DRAFT',
  schedule: {
    dateRangeList: [
      {
        endDate: '2021-12-12T23:59:59',
        pacingShare: null,
        startDate: '2021-11-11T00:00:00',
      },
    ],
    dayPartList: [
      {
        activeDayList: [ActiveDayTypeV5.Monday, ActiveDayTypeV5.Friday],
        firstActiveHour: 19,
        lastActiveHour: 21,
      },
    ],
    deliveryInViewerTime: false,
    timezone: 'America/New_York',
  },
  startDate: '2021-11-11T00:00:00',
  endDate: '2021-12-12T23:59:59',
  status: 'OFF',
  targetingTermValues: mockAdFormTargetingTermValues,
  targetingRule: null,
  type: 'VIDEO',
  unDeletable: false,
  updatedAt: '2021-02-19T23:18:24Z',
  viewabilityVendorList: ['MOAT'],
  sequence: 0,
  adSequence: { ...AD_SEQUENCE_MOCK },
};

export const mockAdFormSchedule: ScheduleV5 = {
  dateRangeList: [
    {
      startDate: '2021-11-11T00:00:00',
      endDate: '2021-12-12T23:59:59',
      pacingShare: null,
    },
  ],
  dayPartList: [
    {
      activeDayList: [ActiveDayTypeV5.Monday, ActiveDayTypeV5.Friday],
      firstActiveHour: 19,
      lastActiveHour: 21,
    },
  ],
  deliveryInViewerTime: false,
  timezone: 'America/New_York',
};

export const adsPageAdMock: AdsPageAd = {
  actualStart: '2022-11-11T23:18:24Z',
  adRotation: {
    rotationId: 'rotationId',
    rotationName: 'test',
    weight: 12,
  },
  adTagList: [
    {
      id: 'billable-tag-id',
      event: adTagEvents.COMPLETE,
      isVerified: true,
      type: adTagTypes.BILLABLE,
      unDeletable: false,
      url: 'https://www.hehehaha.com',
      isIas: null,
    },
  ],
  createdAt: '2021-02-19T23:18:24Z',
  dirty: false,
  creative: null,
  frequencyCapList: [
    { type: 'AD', limit: 10, duration: 20, durationUnit: frequencyCapUnitTypes.HOUR, enabled: false },
    { type: 'AD', limit: 1, duration: 60, durationUnit: frequencyCapUnitTypes.DAY, enabled: false },
  ],
  id: 'test-ad-id',
  lineItem: {
    id: 'line-item-id',
    parentLineOmsLink: {
      id: 'parent-line-oms-link-id',
    },
    schedule: {
      dateRangeList: [
        {
          endDate: '2021-12-12T23:59:59',
          pacingShare: null,
          startDate: '2021-11-11T00:00:00',
        },
      ],
      dayPartList: [
        {
          activeDayList: [ActiveDayTypeV5.Monday, ActiveDayTypeV5.Friday],
          firstActiveHour: 19,
          lastActiveHour: 21,
        },
      ],
      deliveryInViewerTime: false,
      timezone: 'America/New_York',
    },
  },
  name: 'Duplicate ad mock',
  rating: {
    key: 'CHILD_SAFE',
    displayName: 'Child Safe',
  },
  review: reviews.DRAFT,
  schedule: mockAdFormSchedule,
  startDate: '2021-11-11T00:00:00',
  endDate: '2021-12-12T23:59:59',
  status: adStatuses.OFF,
  targetingTermValues: mockAdFormTargetingTermValues,
  targetingRule: null,
  type: adTypes.VIDEO,
  unDeletable: false,
  updatedAt: '2021-02-19T23:18:24Z',
  viewabilityVendorList: [viewabilityVendors.MOAT],
  adSequence: { ...AD_SEQUENCE_MOCK },
  sequence: 0,
};

export const adsPageAdMockWithCreative = {
  ...adsPageAdMock,
  creative: {
    name: 'Test Creative',
    creativeLibraryId: '',
    id: 'creative-id-VIDEO',
    type: CreativeV5Type.Video,
    review: 'PENDING',
    video: { id: 'video-id' },
    mode: 'selectOption' as const,
  },
};

export const creativeIdNoApproved = 'creativeIdNoApproved';

const mockGetCreativeApprovalsNoApproved: MockedResponse<GetCreativeApprovalsQuery> = {
  request: {
    query: GET_CREATIVE_APPROVALS,
    variables: {
      creativeId: creativeIdNoApproved,
    },
  },
  result: {
    data: {
      getCreativeApprovalsV5: {
        approvedAdUuids: [],
        adUuidsWithApprovedVastUrl: [],
      },
    },
  },
};

export const creativeIdWithApprovedAds = 'creativeIdWithApprovedAds';
export const approvedAdId = 'approved-ad-id-1';

const mockGetCreativeApprovalsWithApprovedAds: MockedResponse<GetCreativeApprovalsQuery> = {
  request: {
    query: GET_CREATIVE_APPROVALS,
    variables: {
      creativeId: creativeIdWithApprovedAds,
    },
  },
  result: {
    data: {
      getCreativeApprovalsV5: {
        approvedAdUuids: [approvedAdId],
        adUuidsWithApprovedVastUrl: [],
      },
    },
  },
};

export const mocksGetCreativeApprovals = [mockGetCreativeApprovalsNoApproved, mockGetCreativeApprovalsWithApprovedAds];

export const vastCreative: CreativesBuilderValues = {
  id: 'test-id',
  url: 'test-tag-url',
  type: 'VAST_VIDEO',
  mode: 'build',
  name: 'test-creative-name',
  isSubmitted: true,
};

const sharedCreativeFields = {
  id: 'test-id',
  assetTagList: [{ id: 'test-id', description: 'description', value: 'value', displayName: 'displayName' }],
  creativeLibraryId: 'test-creative-library-id',
  name: 'test-name',
  createdAt: 'test-created-ad',
  rating: 'test-rating',
  review: 'test-review',
  status: 'test-status',
  updatedAt: 'test-updated-at',
  rejectionReason: 'test-rejection-reason',
};

export const savedVastVideoCreative: GetCreativeByIdQuery['getCreativeByIDV5'] = {
  ...sharedCreativeFields,
  type: 'VAST_VIDEO',
  __typename: 'VASTVideoCreativeV5',
  url: 'test-url',
  videoAssetList: [],
  vpaid: false,
};

export const savedVastVideoCreativeWithoutUrl: GetCreativeByIdQuery['getCreativeByIDV5'] = {
  ...sharedCreativeFields,
  type: 'VAST_VIDEO',
  __typename: 'VASTVideoCreativeV5',
  url: null,
  videoAssetList: [],
  vpaid: false,
};

export const savedMagniteCreative: GetCreativeByIdQuery['getCreativeByIDV5'] = {
  ...savedVastVideoCreative,
  type: 'MAGNITE',
  __typename: 'MagniteCreativeV5',
  adSourceId: '',
};

export const savedDraxCreative: GetCreativeByIdQuery['getCreativeByIDV5'] = {
  ...savedVastVideoCreative,
  type: 'DRAX',
  __typename: 'DraxCreativeV5',
};

export const adsPageLineItem = {
  id: 'li-id',
  name: 'li-name',
  campaign: {
    id: 'campaign-id',
    name: 'campaign-name',
    traffickerEmail: 'test@email.com',
    order: {
      salespersonEmail: 'test@email.com',
    },
    adAccount: {
      id: 'ad-account-id',
      timezone: defaultSupportedTimezones.ET,
      organizationId: 'organization-id',
    },
    campaignTagList: [],
    crmLink: {
      id: 'crm-link-id',
      name: 'DYER STAFFing SOLUTIONS GROUP',
    },
    startDate: '2021-11-11T00:00:00',
  },
  startDate: '2021-11-11T00:00:00',
  endDate: '2021-12-12T23:59:59',
  schedule: {
    timezone: defaultSupportedTimezones.ET,
    dateRangeList: [
      {
        startDate: '2021-11-11T00:00:00',
        endDate: '2022-12-12T23:59:59',
        pacingShare: null,
      },
      {
        startDate: '2023-11-11T00:00:00',
        endDate: '2023-12-12T23:59:59',
        pacingShare: null,
      },
    ],
    dayPartList: [
      {
        firstActiveHour: 9,
        lastActiveHour: 18,
        activeDayList: [ActiveDayTypeV5.Monday, ActiveDayTypeV5.Friday],
      },
    ],
    deliveryInViewerTime: false,
    start: '2022-11-11T00:00:00',
    end: '2022-12-12T23:59:59',
  },
  displayPublisherTarget: ['DISNEY_PLUS'],
  billableThirdParty: billableThirdParties.ADVERTISER_NIELSEN_DTA_DEMO,
  isCoppaOrCaru: false,
  draxDeal: null,
  targetingTermValues: [],
  targetingRule: null,
  parentLineOmsLink: {
    id: 'parent-line-oms-link',
  },
};
