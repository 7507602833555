import './VastPreviewBody.scss';

import { Label as TrekLabel } from '@hulu/react-style-components';
import { bem } from 'mission-control-common-components';
import React from 'react';

import type { AssetExtended } from '../../../../_ForCommonLibrary/types/extended';
import type { IngestionTrial } from '../../../../../apis/graphql';
import Alert, { AlertType } from '../../../../Alert';
import { TagInput } from '../../../../Form';
import { DEFAULT_TAG_INPUT_LABEL } from '../../../../Form/TagInput';
import useTagUpload from '../../hooks/useTagUpload';
import type { CreativesBuilderValues } from '../../types';
import { creativeTypeToLabel, getCreativeReviewAlertProps } from '../../utils';
import { VastContent } from '../VastContent';
import { APPROVED_ADS_WARNING_MESSAGE, DEFAULT_TAG_INPUT_LENGTH } from './constants';
import { createVerifiedCreativeWarningMessage } from './utils';
import VastPreviewInput from './VastPreviewInput';

const [block, element] = bem('vast-preview-body');

export type Props = {
  adUuidsWithApprovedVastUrl: string[];
  approvedAdUuids: string[];
  assets?: AssetExtended[] | null;
  creative: CreativesBuilderValues;
  trialsWithoutAssets?: IngestionTrial[];
  label?: string;
};

export const VastPreviewBody = ({
  assets,
  creative,
  trialsWithoutAssets,
  adUuidsWithApprovedVastUrl,
  approvedAdUuids,
}: Props): JSX.Element => {
  const tagInputProps = useTagUpload();

  const creativeReview = getCreativeReviewAlertProps(creative.review);
  const vastOrMagniteLabel = creative.adSourceId ? 'MAGNITE' : creative.type;

  const isTagInputDisabled = approvedAdUuids.length > 0 || adUuidsWithApprovedVastUrl.length > 0;
  const label = assets?.length ? creativeTypeToLabel(vastOrMagniteLabel) : DEFAULT_TAG_INPUT_LABEL;

  const allValuesLocked = assets
    ?.map((asset) => asset.reviewTask?.spReview)
    .every((value): boolean => value === 'LOCKED');

  const vastPreviewInput =
    tagInputProps.url.length >= DEFAULT_TAG_INPUT_LENGTH ? (
      <VastPreviewInput label={label} isTagInputDisabled={isTagInputDisabled} tagInputProps={tagInputProps} />
    ) : null;

  return (
    <div className={block()} data-testid="vast-preview-body">
      <div className={element('info')}>
        <TagInput
          {...tagInputProps}
          disabled={isTagInputDisabled}
          label={label}
          customInputComponent={vastPreviewInput}
        />
        {approvedAdUuids.length > 0 && <span className={element('message')}>{APPROVED_ADS_WARNING_MESSAGE}</span>}
        {approvedAdUuids.length === 0 && adUuidsWithApprovedVastUrl.length > 0 && (
          <Alert type={AlertType.WARNING} bodyText={createVerifiedCreativeWarningMessage(adUuidsWithApprovedVastUrl)} />
        )}
        {creativeReview && !allValuesLocked && <Alert {...creativeReview} />}
      </div>
      <div className={element('content')}>
        <VastContent
          assets={assets}
          creativeId={creative.id || ''}
          creativeName={creative.name}
          trialsWithoutAssets={trialsWithoutAssets}
        />
        {creative.adSourceId && (
          <>
            <TrekLabel>Ad Source ID</TrekLabel> {creative.adSourceId}
          </>
        )}
      </div>
    </div>
  );
};
