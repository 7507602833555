import { gql } from '@apollo/client';

import { TARGETING_RULE_DEFINITION } from '../../../../apis/graphql';
import { ADS_PAGE_AD } from '../../../../common/AdForm/hooks/fragments';

export const CREATE_ADS_PAGE_AD = gql`
  mutation CreateAdsPageAd($adInput: AdInputV5!) {
    createAd: createAdV5(input: $adInput) {
      ...adsPageAd
    }
  }
  ${ADS_PAGE_AD}
`;

export const CREATE_ADS_PAGE_AD_WITH_ASSET = gql`
  mutation CreateAdsPageAdWithAsset($adInput: AdInputV5!, $assetId: ID!, $assetInput: AssetInputV5!) {
    createAd: createAdV5(input: $adInput) {
      ...adsPageAd
    }
    updateReviewAssetV5(assetId: $assetId, input: $assetInput) {
      id
    }
  }
  ${ADS_PAGE_AD}
`;

export const UPDATE_ADS_PAGE_AD = gql`
  mutation UpdateAdsPageAd($adId: ID!, $updateAdInput: UpdateAdInputV5!, $targetingRule: TargetingRuleInput) {
    updateAd: updateAdV5(id: $adId, input: $updateAdInput, targetingRule: $targetingRule) {
      ...adsPageAd
      targetingRule {
        ...targetingRuleDefinition
      }
    }
  }
  ${ADS_PAGE_AD}
  ${TARGETING_RULE_DEFINITION}
`;

export const UPDATE_ADS_PAGE_AD_WITH_ASSET = gql`
  mutation UpdateAdsPageWithAsset(
    $adId: ID!
    $updateAdInput: UpdateAdInputV5!
    $assetId: ID!
    $assetInput: AssetInputV5!
    $targetingRule: TargetingRuleInput
  ) {
    updateAd: updateAdV5(id: $adId, input: $updateAdInput, targetingRule: $targetingRule) {
      ...adsPageAd
      targetingRule {
        ...targetingRuleDefinition
      }
    }
    updateReviewAssetV5(assetId: $assetId, input: $assetInput) {
      id
    }
  }
  ${ADS_PAGE_AD}
  ${TARGETING_RULE_DEFINITION}
`;
