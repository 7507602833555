import { useState } from 'react';

type Stepper = {
  activeStep: number;
  onNextStep: () => void;
  onPrevStep: () => void;
  onResetStep: () => void;
};

/**
 * steps should be enum of numbers in the format:
 *
 * enum Steps {
 *   'FIRST',
 *   'SECOND',
 *   ...
 * }
 */
const useStepper = (steps: Record<number, string>): Stepper => {
  const stepsCount = Object.keys(steps).length / 2;

  const [activeStep, setActiveStep] = useState(0);

  const onNextStep = (): void => {
    if (activeStep < stepsCount - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const onPrevStep = (): void => {
    if (activeStep) {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const onResetStep = (): void => {
    setActiveStep(0);
  };

  return {
    activeStep,
    onNextStep,
    onPrevStep,
    onResetStep,
  };
};

export default useStepper;
