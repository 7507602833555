import type { AssetInputV5 } from '../../../../apis/graphql';
import type { AssetExtended } from '../../../../common/_ForCommonLibrary/types/extended';
import type { AdsPageLineItem } from '../../../../common/AdForm/types';
import { getCountryCodesFromAsset } from './getCountryCodesFromAsset';
import { getCountryCodesFromLineItem } from './getCountryCodesFromLineItem';
// import { getTagListFromAsset } from './getTagListFromAsset';

export function buildAssetInput(lineItem?: AdsPageLineItem, assets?: AssetExtended[]): AssetInputV5 | undefined {
  if (!assets?.length) return;

  const { comments, name, rating, rejectionReasons, type } = assets[0];

  // TODO: Set to undefined for hotfix; Review & potentially re-enable if necessary
  const assetTagList = undefined; //getTagListFromAsset(assets[0]);

  const countriesFromAsset = getCountryCodesFromAsset(assets[0]);
  const countriesFromLineItem = getCountryCodesFromLineItem(lineItem);
  const countryCodes = [...new Set([...countriesFromAsset, ...countriesFromLineItem])];

  return { assetTagList, comments, countryCodes, name, rating, rejectionReasons, type };
}
