import type { MutationFunction } from '@apollo/client';

import type {
  AssetInputV5,
  TargetingRuleInput,
  UpdateAdsPageAdMutation,
  UpdateAdsPageAdMutationVariables,
  UpdateAdsPageWithAssetMutation,
  UpdateAdsPageWithAssetMutationVariables,
} from '../../../../../apis/graphql';
import type { AdFormValues } from '../../../../../common/AdForm/adFormik';
import { constructUpdateAdInput } from './constructUpdateAdInput';

type Mutations = {
  updateAd: MutationFunction<UpdateAdsPageAdMutation>;
  updateAdWithAsset: MutationFunction<UpdateAdsPageWithAssetMutation>;
};

type WithoutAsset = { mutation: Mutations['updateAd']; variables: UpdateAdsPageAdMutationVariables };
type WithAsset = { mutation: Mutations['updateAdWithAsset']; variables: UpdateAdsPageWithAssetMutationVariables };

export function getUpdateMutation(
  mutations: Mutations,
  adFormValues: AdFormValues,
  assetInput?: AssetInputV5,
  targetingRule?: TargetingRuleInput | null
): WithoutAsset | WithAsset {
  // Does the Ad have a valid Asset for mutation?
  if (!!assetInput && adFormValues.creative.type === 'VIDEO' && !!adFormValues.creative.video?.id) {
    const withAsset: WithAsset = {
      mutation: mutations.updateAdWithAsset,
      variables: {
        adId: adFormValues.id || '',
        updateAdInput: constructUpdateAdInput(adFormValues),
        assetId: adFormValues.creative.video?.id || '',
        assetInput,
        targetingRule: targetingRule,
      },
    };
    return withAsset;
  } else {
    const withoutAsset: WithoutAsset = {
      mutation: mutations.updateAd,
      variables: {
        adId: adFormValues.id || '',
        updateAdInput: constructUpdateAdInput(adFormValues),
        targetingRule: targetingRule,
      },
    };
    return withoutAsset;
  }
}
