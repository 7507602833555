import './ExportDropdown.scss';

import { IconDownload } from '@hulu-react-style-components/icons';
import React, { memo, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import type Link from 'react-csv/components/Link';
import type { TableInstance } from 'react-table';

import Loader from '../../../common/Loader';
import { openToastAlert } from '../../../common/ToastAlert/toastAlert';
import type { TraffickingTableName } from '../../../constants';
import { useOnClickOutside } from '../../../hooks';
import bem from '../../../utils/bem';
import { MAX_LIMIT } from '../constants';
import useTraffickerExportState from '../TraffickingPage/hooks/useTraffickerExportState';
import type { EntityModel } from '../TraffickingPage/modelConverters';
import { convertExportFileNameToCorrectFormat, getDataToExport } from './utils';

const [block, element] = bem('export-dropdown');

interface CSVLinkRef extends HTMLAnchorElement, Link {
  link: HTMLAnchorElement;
}

export interface ExportDropdownProps {
  tableInstance: TableInstance<EntityModel>;
  tableName: TraffickingTableName;
  total: number;
}

const ExportDropdown = ({ tableInstance, tableName, total }: ExportDropdownProps): JSX.Element => {
  const [visible, setVisible] = useState<boolean>(false);
  const [needExport, setNeedExport] = useState<boolean>(false);
  const ref = useRef(null);
  const csvLink = useRef<CSVLinkRef>(null);
  const csvFullExportLink = useRef<CSVLinkRef>(null);
  const { fullTableInstance, loadFullTableInstance, loading } = useTraffickerExportState(tableName);
  const exportFileName = convertExportFileNameToCorrectFormat(tableName, new Date(Date.now()));
  const dataToExportInView = getDataToExport(tableName, tableInstance);
  const fullDataToExport = getDataToExport(tableName, fullTableInstance);

  const onExportAll = (): void => {
    if (total <= MAX_LIMIT) {
      setVisible(false);
      loadFullTableInstance();
    } else {
      openToastAlert({
        alertType: 'error',
        message: `Too many entities`,
        description: `We are unable to load more than ${MAX_LIMIT} rows. Please add some more filters.`,
      });
    }
  };

  const onExportInView = (): void => {
    setVisible(false);
    csvLink.current?.link.click();
  };

  useOnClickOutside(ref, (): void => setVisible(false));

  useEffect(() => {
    if (needExport && !loading && fullDataToExport.length > 1) {
      setNeedExport(false);
      setTimeout(() => {
        csvFullExportLink.current?.link.click();
      });
    } else if (!needExport && loading) {
      setNeedExport(true);
    }
  }, [needExport, fullDataToExport, loading]);

  useEffect(() => {
    if (tableInstance.state.hiddenColumns && tableInstance.state.columnOrder) {
      fullTableInstance?.setHiddenColumns(tableInstance.state.hiddenColumns);
      fullTableInstance?.setColumnOrder(tableInstance.state.columnOrder);
    }
  }, [tableInstance.state.hiddenColumns, tableInstance.state.columnOrder, fullTableInstance]);

  return (
    <div ref={ref} className={block()}>
      {loading ? (
        <Loader text="Loading data" className={element('loader')} />
      ) : (
        <div className={element('button')} onClick={(): void => setVisible(true)}>
          <IconDownload className={element('download-icon')} />
          Export
        </div>
      )}
      {visible && (
        <ul className={element('tooltip')}>
          <li onClick={onExportAll}>Export All</li>
          <li onClick={onExportInView}>Export Current View</li>
        </ul>
      )}
      {tableInstance?.data && (
        <CSVLink
          role="link"
          filename={`${exportFileName}.csv`}
          data={dataToExportInView}
          className={element('csv-link')}
          ref={csvLink}
        />
      )}
      {fullDataToExport.length > 1 && (
        <CSVLink
          role="link"
          filename={`${exportFileName}.csv`}
          data={fullDataToExport}
          className={element('csv-link')}
          ref={csvFullExportLink}
        />
      )}
    </div>
  );
};

export default memo(ExportDropdown);
