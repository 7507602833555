import { useQuery } from '@apollo/client';

import type { GetCreativeApprovalsQuery, GetCreativeApprovalsQueryVariables } from '../../../../apis/graphql';
import { GET_CREATIVE_APPROVALS } from './queries/getCreativeApprovals';

type UseFetchCreativeApprovals = {
  adUuidsWithApprovedVastUrl: string[];
  approvedAdUuids: string[];
  loading: boolean;
};

export const useFetchCreativeApprovals = (creativeId: string): UseFetchCreativeApprovals => {
  const { data, loading } = useQuery<GetCreativeApprovalsQuery, GetCreativeApprovalsQueryVariables>(
    GET_CREATIVE_APPROVALS,
    {
      variables: { creativeId },
      skip: !creativeId,
    }
  );

  const adUuidsWithApprovedVastUrl = data?.getCreativeApprovalsV5.adUuidsWithApprovedVastUrl || [];
  const approvedAdUuids = data?.getCreativeApprovalsV5.approvedAdUuids || [];

  return {
    adUuidsWithApprovedVastUrl,
    approvedAdUuids,
    loading,
  };
};
