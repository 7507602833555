import { useMutation } from '@apollo/client';

import type { SaveTraffickingFiltersMutation, SaveTraffickingFiltersMutationVariables } from '../../../../apis/graphql';
import { SearchParams, TraffickingTableName } from '../../../../constants';
import { useFiltersContext } from '../../../../contexts/FilterContext';
import { convertStringToBoolean } from '../../utils';
import { SAVE_TRAFFICKING_FILTERS } from './queries/saveTraffickingFilters';

type UseSaveTraffickingFilters = {
  loading: boolean;
  saveFilters: () => Promise<string>;
};

export const useSaveTraffickingFilters = (): UseSaveTraffickingFilters => {
  const [saveTraffickingFilters, { loading }] = useMutation<
    SaveTraffickingFiltersMutation,
    SaveTraffickingFiltersMutationVariables
  >(SAVE_TRAFFICKING_FILTERS);

  const { filters } = useFiltersContext();
  const saveFilters = async (): Promise<string> => {
    const { data } = await saveTraffickingFilters({
      variables: {
        input: {
          ...filters,
          selectedTab: filters.selectedTab ?? TraffickingTableName.campaigns,
          dateFilter:
            typeof filters.dateFilter.value === 'string' || filters.dateFilter.value === null
              ? {
                  label: filters.dateFilter.label,
                }
              : filters.dateFilter,
          /** On UI boolean filters are represented as string[] for the sake of simplicity of using them with radio buttons and storing those filters.
           Hence we convert the string format filters to a boolean before saving trafficking filters for the creation of a shareable link  */
          isAddedValue: convertStringToBoolean(filters.isAddedValue ?? []),
          isCoppaOrCaru: convertStringToBoolean(filters.isCoppaOrCaru ?? []),
          isMakegood: convertStringToBoolean(filters.isMakegood ?? []),
          isRotation: convertStringToBoolean(filters.isRotation ?? []),
          isSequence: convertStringToBoolean(filters.isSequence ?? []),
        },
      },
    });

    const shareableId = data?.saveTraffickingFilters.id;

    if (!shareableId) {
      throw new Error();
    }

    const { protocol, host } = window.location;

    return `${protocol}//${host}/trafficking?${SearchParams.ShareableId}=${shareableId}`;
  };

  return {
    saveFilters,
    loading,
  };
};
