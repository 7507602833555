import { useCallback, useState } from 'react';
import type { DeselectOptionActionMeta, InputActionMeta, SelectOptionActionMeta } from 'react-select';

import { SelectInputAction } from '../../../../../common/constants/select';
import type { DropdownOption } from '../../../../../common/Dropdown';
import { ActionTypes } from '../../../../../common/MultiSelectDropdown';
import type { MultiSelectValue } from '../../../../../common/MultiSelectDropdown/MultiSelectDropdown';
import { useTraffickerState } from '../../../TraffickingPage/hooks';
import type { MultiDropdownFilterProps } from '../MultiDropdownFilter';

interface UseMultiDropdownFilterProps extends Omit<MultiDropdownFilterProps, 'useOptions'> {
  options: DropdownOption[];
  onSearch?: (searchTerm: string) => void;
  onResetOptions?: () => void;
}

type UseMultiDropdownFilter = {
  selectedDropdownOptions: DropdownOption[];
  onHandleSearchValues: (value: string, { action }: InputActionMeta) => void;
  onHandleChange: (
    value: MultiSelectValue,
    meta: SelectOptionActionMeta<DropdownOption> | DeselectOptionActionMeta<DropdownOption>
  ) => void;
  onSelectAll: () => void;
  onClearAll: () => void;
  onCloseDropdownMenu: () => void;
};

export const useMultiDropdownFilter = ({
  options,
  searchParam,
  selectedValues,
  onSearch,
  onSetSelectedFilter,
  onRemoveFilter,
  onResetOptions,
}: UseMultiDropdownFilterProps): UseMultiDropdownFilter => {
  const { applyCommonFilter } = useTraffickerState();

  const [selectedDropdownOptions, setSelectedDropdownOptions] = useState<DropdownOption[]>(selectedValues);

  const onHandleSearchValues = useCallback(
    (value: string, { action }: InputActionMeta): void => {
      if (action === SelectInputAction.InputChange) {
        if (onSearch) {
          onSearch(value);
        }
      }
    },
    [onSearch]
  );

  const onHandleChange = useCallback(
    (_, meta: SelectOptionActionMeta<DropdownOption> | DeselectOptionActionMeta<DropdownOption>): void => {
      if (meta.action === ActionTypes.select && meta.option?.value) {
        const { option } = meta;

        setSelectedDropdownOptions((options) => [...new Set([...options, option])]);
      }

      if (meta.action === ActionTypes.deselect) {
        setSelectedDropdownOptions((existingSelectedOptions) =>
          existingSelectedOptions.filter(({ value: selectedOption }) => selectedOption !== meta.option?.value)
        );
      }
    },
    [setSelectedDropdownOptions]
  );

  const onSelectAll = useCallback(() => {
    setSelectedDropdownOptions([...options, ...selectedDropdownOptions]);
  }, [options, selectedDropdownOptions, setSelectedDropdownOptions]);

  const onClearAll = useCallback(() => {
    setSelectedDropdownOptions([]);
  }, [setSelectedDropdownOptions]);

  const onCloseDropdownMenu = (): void => {
    if (selectedDropdownOptions.length) {
      applyCommonFilter(searchParam, selectedDropdownOptions);

      onSetSelectedFilter();
    } else {
      onRemoveFilter(searchParam);
    }

    onResetOptions?.();
  };

  return {
    selectedDropdownOptions,
    onHandleSearchValues,
    onHandleChange,
    onSelectAll,
    onClearAll,
    onCloseDropdownMenu,
  };
};
