import type { ApolloError } from '@apollo/client';
import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

import type { AdEdgeV5, UpdateAdInputV5, UpdateAdMutation, UpdateAdMutationVariables } from '../../../../apis/graphql';
import { getErrorMessageFromGraphQlErrors } from '../../../../apis/graphql';
import type { EditableInputVariant } from '../../../../common/EditableTableCells/EditableCell/EditableCell';
import { getGraphQLErrorExtensionsMessage } from '../../../../common/utils';
import { AD_TRAFFICKING_COLUMN } from './fragments';
import { UPDATE_TRAFFICKING_PAGE_AD } from './mutations';
import useCellVariant from './useCellVariant';

type ExistingAds = {
  __typename?: 'AdsPage';
  edges: Array<AdEdgeV5>;
  total: number;
  errors?: string[];
};

type UseUpdateAd<T extends keyof UpdateAdInputV5> = {
  cellVariant: EditableInputVariant;
  updateAdFields: (newValues: Pick<UpdateAdInputV5, T>) => void;
  errors: string[];
};

const useUpdateAd = <T extends keyof UpdateAdInputV5>(adId: string): UseUpdateAd<T> => {
  const [graphQLErrors, setGraphQLErrors] = useState<string[]>([]);

  const [updateAd, mutationResult] = useMutation<UpdateAdMutation, UpdateAdMutationVariables>(
    UPDATE_TRAFFICKING_PAGE_AD
  );

  useEffect(() => {
    const error = mutationResult?.error;
    let message = '';

    if (error) {
      message = (error?.graphQLErrors ? getErrorMessageFromGraphQlErrors(error?.graphQLErrors) : error?.message) || '';
    }

    if (message.length > 0) {
      setGraphQLErrors((prev) => [...prev, message]);
    }
  }, [mutationResult.error]);

  const cellVariant = useCellVariant(mutationResult);

  const client = useApolloClient();

  const updateAdFields = useCallback(
    (newValues: Pick<UpdateAdInputV5, T>): void => {
      // we need this data because after update we can't receive some fields from campaign-manager
      // according to bug AX-3785
      const oldCacheData = client.readFragment({
        id: `AdV5:${adId}`,
        fragment: AD_TRAFFICKING_COLUMN,
      });

      updateAd({
        variables: {
          id: adId,
          updateAdInput: newValues,
        },
        update(cache, { data }) {
          const { startDate, endDate, displayPublisherTarget } = oldCacheData;
          const updatedData = { ...data?.updateAd, startDate, endDate, displayPublisherTarget };

          cache.modify({
            fields: {
              adsV5(existingAds: ExistingAds) {
                cache.writeFragment({
                  fragment: AD_TRAFFICKING_COLUMN,
                  data: updatedData,
                  variables: {
                    id: adId,
                    ads: newValues,
                  },
                });

                return existingAds;
              },
            },
          });
        },
      }).catch((error: ApolloError) => {
        const message = getGraphQLErrorExtensionsMessage(error);

        if (message) {
          setGraphQLErrors((prev) => [...prev, message]);
        }
      });
    },
    [adId, client, updateAd]
  );

  return {
    cellVariant,
    updateAdFields,
    errors: graphQLErrors,
  };
};

export default useUpdateAd;
