import { useApolloClient } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { get } from 'lodash';
import { useCallback } from 'react';

import type { AdsPageV5, LineItemsPageV5, Query } from '../../../../apis/graphql';
import { TraffickingTableName } from '../../../../constants';
import { useFiltersContext } from '../../../../contexts/FilterContext';
import { MAX_LIMIT } from '../../constants';
import { GET_ADS, GET_ADS_IDS, GET_LINE_ITEMS_IDS, GET_LINEITEMS_BY_CAMPAIGNID_LIST } from './queries';
import type { QueryVariables } from './useTraffickerState';
import { DEFAULT_LIMIT } from './useTraffickerTable';

type UseAssociatedEntities = {
  getAssociatedEntities: (
    tableName: TraffickingTableName,
    ids: string[]
  ) => Promise<Partial<Record<TraffickingTableName, string[]>>>;
};

type AssociatedEntitiesQueryVariables = {
  lineItemQueryVariables: QueryVariables;
  adsQueryVariables: QueryVariables;
};

//Need this transformation since we have gql queries lineItemsV5 and adsV5, but in gql requests we fetch them as lineItems and ads
const transformDataType = (
  data: Pick<Query, 'lineItemsV5' | 'adsV5'>
): { lineItems: LineItemsPageV5; ads: AdsPageV5 } => {
  return (data as unknown) as { lineItems: LineItemsPageV5; ads: AdsPageV5 };
};

export const useAssociatedEntities = ({
  lineItemQueryVariables,
  adsQueryVariables,
}: AssociatedEntitiesQueryVariables): UseAssociatedEntities => {
  const { query: clientQuery, cache } = useApolloClient();
  const { filters } = useFiltersContext();

  const queryAssociatedItems = useCallback(
    async <T extends Partial<QueryVariables>>(
      query: DocumentNode,
      key: 'lineItems' | 'ads',
      queryVariables: Partial<T>
    ): Promise<string[]> => {
      const variables = { limit: MAX_LIMIT, ...queryVariables } as T;

      const { data } = await clientQuery<Pick<Query, 'lineItemsV5' | 'adsV5'>, T>({ query, variables });

      return transformDataType(data)[key].edges.map(({ node }) => node.id);
    },
    [clientQuery]
  );

  const readAssociatedItems = useCallback(
    async ({
      ids,
      query,
      variables,
      key,
      path,
    }: {
      ids: string[];
      query: DocumentNode;
      variables: QueryVariables;
      key: 'lineItems' | 'ads';
      path: string;
    }): Promise<string[]> => {
      const data = cache.readQuery<Pick<Query, 'lineItemsV5' | 'adsV5'>>({ query, variables });

      if (!data) return [];

      return transformDataType(data)[key].edges.reduce<string[]>((acc, { node }) => {
        const entityId = get(node, path);

        if (ids.includes(entityId)) return [...acc, node.id];

        return acc;
      }, []);
    },
    [cache]
  );

  const getAssociatedItems = useCallback(
    async <T>({
      query,
      ids,
      key,
      variables,
      cachedQuery,
      cachedVariables,
      path,
    }: {
      ids: string[];
      query: DocumentNode;
      variables: Partial<T>;
      cachedQuery: DocumentNode;
      cachedVariables: QueryVariables;
      key: 'lineItems' | 'ads';
      path: string;
    }): Promise<string[]> => {
      const selectedRowIds = filters[key]?.selectedRowIds;

      if (!selectedRowIds) return [];

      return selectedRowIds.length > DEFAULT_LIMIT
        ? queryAssociatedItems(query, key, variables)
        : readAssociatedItems({ query: cachedQuery, ids, variables: cachedVariables, key, path });
    },
    [filters, queryAssociatedItems, readAssociatedItems]
  );

  const getLineItemsAssociatedEntities = useCallback(
    async (ids: string[]): Promise<Partial<Record<TraffickingTableName, string[]>>> => {
      const adsIds = await getAssociatedItems({
        query: GET_ADS_IDS,
        ids,
        key: 'ads',
        variables: {
          lineItemsIds: ids,
        },
        cachedQuery: GET_ADS,
        cachedVariables: adsQueryVariables,
        path: 'lineItem.id',
      });

      return {
        ads: adsIds,
      };
    },
    [adsQueryVariables, getAssociatedItems]
  );

  const getCampaignsAssociatedEntities = useCallback(
    async (ids: string[]): Promise<Partial<Record<TraffickingTableName, string[]>>> => {
      const lineItemsIds = await getAssociatedItems({
        query: GET_LINE_ITEMS_IDS,
        ids,
        key: 'lineItems',
        variables: { ids },
        cachedQuery: GET_LINEITEMS_BY_CAMPAIGNID_LIST,
        cachedVariables: lineItemQueryVariables,
        path: 'campaign.id',
      });

      const adsIds = await getAssociatedItems({
        query: GET_ADS_IDS,
        ids,
        key: 'ads',
        variables: {
          campaignsIds: ids,
        },
        cachedQuery: GET_ADS,
        cachedVariables: adsQueryVariables,
        path: 'lineItem.campaign.id',
      });

      return {
        lineItems: lineItemsIds,
        ads: adsIds,
      };
    },
    [adsQueryVariables, getAssociatedItems, lineItemQueryVariables]
  );

  const getAssociatedEntities = useCallback(
    (tableName: TraffickingTableName, ids: string[]): Promise<Partial<Record<TraffickingTableName, string[]>>> => {
      if (tableName === TraffickingTableName.campaigns) return getCampaignsAssociatedEntities(ids);
      if (tableName === TraffickingTableName.lineItems) return getLineItemsAssociatedEntities(ids);

      return {} as Promise<Record<TraffickingTableName, string[]>>;
    },
    [getCampaignsAssociatedEntities, getLineItemsAssociatedEntities]
  );

  return {
    getAssociatedEntities,
  };
};
