import './AdForm.scss';

import { useLocation } from '@hulu/react-router-dom';
import React, { useMemo, useState } from 'react';

import type { DayPartV5 } from '../../../apis/graphql';
import { AdReviewV5, AdTypeV5, Review } from '../../../apis/graphql';
import { Creative, Details, Metadata, Tags, Targeting } from '../../../common/AdForm';
import AdSideNav from '../../../common/AdForm/AdSideNav';
import { AdFormSection } from '../../../common/AdForm/AdSideNav/AdSideNav';
import CreativeBreadcrumb from '../../../common/AdForm/CreativeBreadcrumb';
import { CreativeEditMode } from '../../../common/AdForm/CreativeBuilders/types';
import type { AdsPageAd } from '../../../common/AdForm/hooks/types';
import Scheduling, { DEFAULT_DAY_PART } from '../../../common/AdForm/Scheduling';
import type { AdsPageLineItem, AdsPageLineItemType } from '../../../common/AdForm/types';
import { generateNielsenErrorMessage } from '../../../common/AdForm/utils';
import DefaultFormContainer from '../../../common/DefaultFormContainer/DefaultFormContainer';
import DefaultFormSectionWrapper from '../../../common/DefaultFormSectionWrapper';
import { useFieldArray } from '../../../common/Form/hooks';
import type { ClearableFrequencyCap } from '../../../common/FrequencyCaps/FrequencyCaps';
import { useFormStateManager } from '../../../common/hooks/useFormStateManager';
import { useTrackFormFieldChanges } from '../../../common/hooks/useTrackFormFieldChanges';
import WindowPrompt from '../../../common/WindowPrompt';
import type { AdType, Config } from '../../../configs';
import { PathName } from '../../../constants';
import type { AdTag, Nullable, TargetingTermValue } from '../../../models';
import bem from '../../../utils/bem';
import { getPublisherTargetingList } from '../../Trafficking/TraffickingPage/columns';
import { CHANGE_SELECTED_AD_CONFIRMATION_MSG } from '../AdList/constants';
import { AD_TAG_LIST_FIELD, CREATE_AD_TITLE, EDIT_AD_TITLE, HANDLE_RESET_TITLE, SUBMIT_FORM_TITLE } from './constants';

const [block, element] = bem('ad-form');

interface AdFormProps {
  permittedAdTypes: Config<AdType>[];
  isSubmitting: boolean;
  submitForm: (e: Event) => void;
  lineItemTargetingTermValues: TargetingTermValue[];
  parentLineItem: Nullable<AdsPageLineItem>;
  selectedAd: AdsPageAd;
  isAdInitiallyWithBillableTag: boolean;
  lineItemCountry: string | undefined;
}

function AdForm({
  parentLineItem,
  permittedAdTypes,
  isSubmitting,
  submitForm,
  lineItemTargetingTermValues,
  selectedAd,
  isAdInitiallyWithBillableTag,
  lineItemCountry,
}: AdFormProps): JSX.Element {
  const { resetForm, currentFormState } = useFormStateManager();
  const publisher = getPublisherTargetingList({ value: parentLineItem?.displayPublisherTarget || null });
  const { areFormFieldsDirty } = useTrackFormFieldChanges();

  const [stashedDayPartList, setStashedDayPartList] = useState<DayPartV5[]>([]);
  const [stashedFrequencyCapList, setStashedFrequencyCapList] = useState<ClearableFrequencyCap[]>([]);

  const isControlButtonsDisabled = !areFormFieldsDirty || isSubmitting;

  const location = useLocation();
  const isEditForm = location.pathname === PathName.adsEdit;

  const isExistingAd = !!currentFormState.values?.id;

  const lineItemType: AdsPageLineItemType = parentLineItem?.draxDeal?.dealId ? 'DRAX' : 'UNKNOWN';

  const handleReset = (): void => {
    setStashedDayPartList([DEFAULT_DAY_PART]);
    setStashedFrequencyCapList([]);
    resetForm();
  };

  const unDeletableAd = isExistingAd && !!selectedAd?.unDeletable;
  const isAdApproved = selectedAd?.review.key === Review.Approved;

  const [, { value: adTagList }, { removeAt, replaceAt, push }] = useFieldArray<AdTag>(AD_TAG_LIST_FIELD);
  const listHelpers = useMemo(() => ({ removeAt, replaceAt, push }), [removeAt, replaceAt, push]);

  const nielsenTagWarning = useMemo(() => {
    const isCoppaOrCaru = parentLineItem?.isCoppaOrCaru || false;
    const campaignStartDate = parentLineItem?.campaign?.startDate || '';
    const isPausedAd = permittedAdTypes.some((adType: Config<AdType>) => adType.key === AdTypeV5.Pause);
    return generateNielsenErrorMessage(adTagList, isCoppaOrCaru, campaignStartDate, lineItemCountry, isPausedAd);
  }, [adTagList, parentLineItem, lineItemCountry, permittedAdTypes]);

  return (
    <DefaultFormContainer
      title={isEditForm ? EDIT_AD_TITLE : CREATE_AD_TITLE}
      handleResetTitle={HANDLE_RESET_TITLE}
      submitTitle={SUBMIT_FORM_TITLE}
      handleReset={handleReset}
      submitForm={submitForm}
      isHandleResetDisabled={isControlButtonsDisabled}
      isSubmitFormDisabled={isControlButtonsDisabled}
      handleResetAriaLabel="discard changes"
      submitFormAriaLabel="save ad"
    >
      <>
        <AdSideNav className={element('side-nav')} containerId="formContainer" />
        <form className={block()} onSubmit={(e): void => e.preventDefault()}>
          <div className={element('content')} id="formContainer">
            {/* Where the id is set in these, pass in refs */}
            <DefaultFormSectionWrapper title="Details" sectionName={AdFormSection.DETAILS}>
              <Details
                permittedAdTypes={permittedAdTypes}
                isDeliveryStatusDisabled={selectedAd?.review.key !== AdReviewV5.Approved}
              />
            </DefaultFormSectionWrapper>
            <DefaultFormSectionWrapper
              title="Creative"
              sectionName={AdFormSection.CREATIVE}
              customTitle={!isAdApproved ? <CreativeBreadcrumb /> : null}
            >
              <Creative
                lineItemType={lineItemType}
                editMode={isAdApproved ? CreativeEditMode.partly : CreativeEditMode.full}
              />
            </DefaultFormSectionWrapper>
            <DefaultFormSectionWrapper title="Tags for Third Party Measurement" sectionName={AdFormSection.TAGS}>
              <Tags
                isAdInitiallyWithBillableTag={isAdInitiallyWithBillableTag}
                unDeletableAd={unDeletableAd}
                adTagList={adTagList}
                listHelpers={listHelpers}
                nielsenTagWarning={nielsenTagWarning}
              />
            </DefaultFormSectionWrapper>
            <DefaultFormSectionWrapper title="Targeting" sectionName={AdFormSection.TARGETING}>
              <Targeting
                lineItemTargetingTermValues={lineItemTargetingTermValues}
                publisher={publisher}
                countryCode={lineItemCountry}
                publisherId={
                  (parentLineItem?.displayPublisherTarget && parentLineItem?.displayPublisherTarget[0]) || ''
                }
              />
            </DefaultFormSectionWrapper>
            <DefaultFormSectionWrapper title="Scheduling" sectionName={AdFormSection.SCHEDULING}>
              <Scheduling
                lineItemSchedule={parentLineItem?.schedule || null}
                stashedDayPartList={stashedDayPartList}
                setStashedDayPartList={setStashedDayPartList}
              />
            </DefaultFormSectionWrapper>
            <DefaultFormSectionWrapper title="Metadata" sectionName={AdFormSection.METADATA}>
              <Metadata
                stashedFrequencyCapList={stashedFrequencyCapList}
                setStashedFrequencyCapList={setStashedFrequencyCapList}
                isCoppaOrCaru={parentLineItem?.isCoppaOrCaru || false}
              />
            </DefaultFormSectionWrapper>
          </div>
        </form>
        <WindowPrompt
          promptCondition={areFormFieldsDirty && !isSubmitting}
          message={CHANGE_SELECTED_AD_CONFIRMATION_MSG}
        />
      </>
    </DefaultFormContainer>
  );
}

export default AdForm;
