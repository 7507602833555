import type { Cell } from 'react-table';

import type { GeneralPacingStatus } from '../../../apis/graphql';
import { computeRenderValue } from '../../../common/EditableTableCells/EditableFrequencyCap/EditableFrequencyCap';
import { getTimezoneWithGMT } from '../../../common/SelectTimeZone/utils';
import type { DisplayEnum, ViewabilityVendor } from '../../../configs';
import { viewerLocalTimeLabel } from '../../../configs';
import { fromEmailToName } from '../../../utils/common';
import {
  formatAdStartAndEndDateToCorrectFormat,
  formatDollarAmount,
  formatPercentage,
  formatStringDate,
  formatWeightToPercentage,
} from '../../../utils/formatting';
import { DELIVERY_FORENSICS_LINK } from '../constants';
import { AlertFiled } from '../TraffickingPage/cells/AlertCell/AlertCell';
import { getUniqueCountriesString } from '../TraffickingPage/cells/CountryCell/utils';
import { cellContentsByPacingStatus } from '../TraffickingPage/cells/PacingStatusCell/constants';
import { getAdSequenceName, getPublisherTargetingList, stringToDateTimeString } from '../TraffickingPage/columns';
import { getAdsAlertFlags } from '../TraffickingPage/columns/getAdsColumns';
import { getCampaignAlertFlags } from '../TraffickingPage/columns/getCampaignsColumns';
import { getLineItemsAlertFlags } from '../TraffickingPage/columns/getLineItemsColumns';
import type { AdModel, CampaignModel, EntityModel, LineItemModel } from './../TraffickingPage/modelConverters/index';
import { formatAlertColumnToCorrectValue, formatBooleanToYes, formatObjectToCSVValue } from './utils';

export type ColumnsValueAccessors = {
  [key: string]: (cell: Cell<EntityModel>) => string;
};

export enum ExportAlertColumnMessage {
  ZeroDelivery = 'zero delivery',
  MisalignedSchedule = 'scheduling conflict',
  InventoryBLock = 'has inventory block',
  LineItem = 'has unlinked line item',
}

export const ALERT_FIELD_TO_DISPLAY_NAME_CONFIG: Record<AlertFiled, ExportAlertColumnMessage> = {
  [AlertFiled.ZeroDelivery]: ExportAlertColumnMessage.ZeroDelivery,
  [AlertFiled.MisalignedSchedule]: ExportAlertColumnMessage.MisalignedSchedule,
  [AlertFiled.InventoryBLock]: ExportAlertColumnMessage.InventoryBLock,
  [AlertFiled.LineItem]: ExportAlertColumnMessage.LineItem,
};

const accessors = {
  displayStatus: (cell: Cell<EntityModel>): string =>
    'subStatus' in cell.row.original &&
    cell.row.original.subStatus &&
    typeof cell.row.original.subStatus === 'object' &&
    'displayName' in cell.row.original.subStatus
      ? cell.row.original.subStatus.displayName
      : cell.value.displayName,
  displayName: (cell: Cell<EntityModel>): string => cell.value.displayName,
  startEndDate: (cell: Cell<EntityModel>): string => (cell.value ? formatStringDate(cell.value) : ''),
  startEndDateTime: (cell: Cell<EntityModel>): string =>
    cell.value ? (formatAdStartAndEndDateToCorrectFormat(cell.value) as string) : '',
  createUpdateDate: (cell: Cell<EntityModel>): string =>
    cell.value ? (stringToDateTimeString({ value: cell.value }) as string) : '',
  percentageValues: (cell: Cell<EntityModel>): string => (cell.value ? formatPercentage(cell.value) : ''),
  dollarAmount: (cell: Cell<EntityModel>): string => (cell.value ? formatDollarAmount(cell.value) : ''),
  deliveryForensics: (cell: Cell<EntityModel>): string => `${DELIVERY_FORENSICS_LINK}?line-item-id=${cell.row.id}`,
  displayPublisherTarget: (cell: Cell<EntityModel>): string => getPublisherTargetingList(cell),
  frequencyCapList: (cell: Cell<EntityModel>): string => computeRenderValue(cell.value).replace(',', ''),
  isCoppaOrCaru: formatBooleanToYes,
  isAddedValue: formatBooleanToYes,
  isMakegood: formatBooleanToYes,
  generalPacingStatus: (cell: Cell<EntityModel>): string =>
    cellContentsByPacingStatus[cell.value as GeneralPacingStatus]?.title || '',
  pacingType: (cell: Cell<EntityModel>): string => cell.value.pacingType.displayName,
  priorityName: (cell: Cell<EntityModel>): string => cell.value.name,
  rotationName: (cell: Cell<EntityModel>): string => cell.value?.rotationName || '',
  scheduleTimezoneOffset: (cell: Cell<EntityModel>): string => {
    if (typeof cell.value === 'string') return cell.value;
    if (cell.value.deliveryInViewerTime) return viewerLocalTimeLabel;
    return getTimezoneWithGMT(cell.value.timezone);
  },
  traffickerEmail: (cell: Cell<EntityModel>): string => fromEmailToName(cell.value),
  tier: (cell: Cell<EntityModel>): string => formatObjectToCSVValue(cell.value),
  unitCost: (cell: Cell<EntityModel>): string => (cell.value ? formatDollarAmount(cell.value / 1000000) : ''),
  viewabilityVendorList: (cell: Cell<EntityModel>): string =>
    cell.value.map((vv: DisplayEnum<ViewabilityVendor>) => vv.displayName).join(' '),
  weight: (cell: Cell<EntityModel>): string => formatWeightToPercentage(cell.value?.weight ? cell.value?.weight : 0),
  sequenceName: (cell: Cell<EntityModel>): string => getAdSequenceName(cell.value),
  country: (cell: Cell<EntityModel>) => getUniqueCountriesString(cell.value),
  adAlert: (cell: Cell<EntityModel>) => formatAlertColumnToCorrectValue(getAdsAlertFlags(cell.row.original as AdModel)),
  lineItemAlert: (cell: Cell<EntityModel>) =>
    formatAlertColumnToCorrectValue(getLineItemsAlertFlags(cell.row.original as LineItemModel)),
  campaignAlert: (cell: Cell<EntityModel>) =>
    formatAlertColumnToCorrectValue(getCampaignAlertFlags(cell.row.original as CampaignModel)),
};

const {
  displayStatus,
  displayName,
  startEndDate,
  startEndDateTime,
  createUpdateDate,
  percentageValues,
  dollarAmount,
  deliveryForensics,
  displayPublisherTarget,
  frequencyCapList,
  isCoppaOrCaru,
  generalPacingStatus,
  pacingType,
  priorityName,
  rotationName,
  scheduleTimezoneOffset,
  traffickerEmail,
  tier,
  unitCost,
  viewabilityVendorList,
  weight,
  sequenceName,
  country,
  isAddedValue,
  isMakegood,
  adAlert,
  lineItemAlert,
  campaignAlert,
} = accessors;

export const columnsValueAccessors: ColumnsValueAccessors = {
  ActualStartDate: startEndDate,
  campaignBudget: dollarAmount,
  CreatedAt: createUpdateDate,
  DeliveryBufferPercent: percentageValues,
  deliveryForensics: deliveryForensics,
  DisplayPublisherTarget: displayPublisherTarget,
  End: startEndDate,
  frequencyCapList: frequencyCapList,
  FrontLoadPercent: percentageValues,
  IsCoppaOrCaru: isCoppaOrCaru,
  GeneralPacingStatus: generalPacingStatus,
  MetricsPercentPacing: percentageValues,
  MetricsPercentExpected: percentageValues,
  MetricsPercentCompletions: percentageValues,
  MetricsRevenueAtRisk: dollarAmount,
  MetricsUnderPacingRevenue: dollarAmount,
  PacingType: pacingType,
  PriorityName: priorityName,
  rating: displayName,
  Review: displayName,
  RotationName: rotationName,
  Revenue: dollarAmount,
  Status: displayStatus,
  ScheduleStart: startEndDate,
  ScheduleEnd: startEndDate,
  Start: startEndDate,
  ScheduleTimezoneOffset: scheduleTimezoneOffset,
  TraffickerEmail: traffickerEmail,
  Tier: tier,
  UnitCost: unitCost,
  UpdatedAt: createUpdateDate,
  ViewabilityVendorList: viewabilityVendorList,
  Weight: weight,
  SequenceName: sequenceName,
  IsAddedValue: isAddedValue,
  IsMakegood: isMakegood,
};

export const adsColumnsCustomValueAccessors: ColumnsValueAccessors = {
  ScheduleStart: startEndDateTime,
  ScheduleEnd: startEndDateTime,
  Alert: adAlert,
};

export const campaignsCustomValueAccessors: ColumnsValueAccessors = {
  Country: country,
  Alert: campaignAlert,
};

export const lineItemsCustomValueAccessors: ColumnsValueAccessors = {
  Alert: lineItemAlert,
};
