import { useMutation } from '@apollo/client';

import type { DeleteAdsMutation } from '../../../../apis/graphql';
import { openToastAlert } from '../../../../common/ToastAlert/toastAlert';
import { MISSION_CONTROL_SUPPORT_EMAIL } from '../../../../constants';
import { DELETE_TRAFFICKING_PAGE_ADS } from './mutations';

interface ReturnType {
  deleteAds: (ids: string[]) => Promise<void>;
  loading: boolean;
}

const useDeleteAds = (): ReturnType => {
  const [deleteAdsMutation, { loading }] = useMutation<DeleteAdsMutation>(DELETE_TRAFFICKING_PAGE_ADS);

  const deleteAds = async (ids: string[]): Promise<void> => {
    try {
      await deleteAdsMutation({
        variables: {
          ids,
        },
      });
      openToastAlert({
        alertType: 'success',
        message: `${ids.length > 1 ? 'Ads' : 'Ad'} Deleted Successfully`,
      });
    } catch (error) {
      openToastAlert({
        alertType: 'error',
        message: `${ids.length > 1 ? 'Ads' : 'Ad'} Deletion Failed`,
        description: (
          <span>
            Something went wrong when deleting {ids.length > 1 ? 'ads' : 'ad'}. Please try again in a few minutes or
            email us at
            <br />
            <a href={`mailto:${MISSION_CONTROL_SUPPORT_EMAIL}`}>{MISSION_CONTROL_SUPPORT_EMAIL}</a>
          </span>
        ),
      });
    }
  };

  return {
    deleteAds,
    loading,
  };
};

export default useDeleteAds;
