import { gql } from '@apollo/client';

export const CAMPAIGN_TRAFFICKING_COLUMNS = gql`
  fragment campaignTraffickingColumns on CampaignV5 {
    __typename
    id
    name
    adAccount {
      id
      name
      timezone
      organizationId
    }
    crmLink {
      id
      name
    }
    advertiser
    agency
    budget
    campaignTagList {
      id
      type
      url
      isVerified
    }
    hasZeroDeliveryRisk
    hasMisalignedSchedule
    orderOmsLink {
      id
    }
    orderType
    trackingId
    traffickerEmail
    startDate
    endDate
    createdAt
    updatedAt
    industryName
    order {
      salespersonEmail
    }
    lineItemList {
      country
    }
  }
`;
