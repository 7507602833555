import { useMutation } from '@apollo/client';

import type { UpdatePacingTypeMutation, UpdatePacingTypeMutationVariables } from '../../../../apis/graphql';
import { AlertType } from '../../../../common/Alert';
import type { EditableInputVariant } from '../../../../common/EditableTableCells/EditableCell/EditableCell';
import { openToastAlert } from '../../../../common/ToastAlert/toastAlert';
import { MISSING_LINE_ITEM_DURING_PACING_TYPE_UPDATE_DESCRIPTION, MISSING_LINE_ITEM_ERROR_MESSAGE } from './constants';
import { UPDATE_LINE_ITEM_PACING_TYPE } from './mutations/pacingType';
import useCellVariant from './useCellVariant';

type ReturnedHookType = {
  cellVariant: EditableInputVariant;
  updatePacingType: (id: string, pacingType: string) => void;
  loading: boolean;
};

const useUpdatePacingType = (): ReturnedHookType => {
  const [updateLineItemPacingType, mutationResult] = useMutation<
    UpdatePacingTypeMutation,
    UpdatePacingTypeMutationVariables
  >(UPDATE_LINE_ITEM_PACING_TYPE);

  const cellVariant = useCellVariant(mutationResult);

  const updatePacingType = (id: string, pacingType: string): void => {
    if (!id) {
      openToastAlert({
        alertType: AlertType.ERROR,
        message: MISSING_LINE_ITEM_ERROR_MESSAGE,
        description: MISSING_LINE_ITEM_DURING_PACING_TYPE_UPDATE_DESCRIPTION,
        restrictToPage: false,
      });

      return;
    }

    updateLineItemPacingType({
      variables: {
        id,
        pacingType,
      },
    });
  };

  return {
    cellVariant,
    updatePacingType,
    loading: mutationResult.loading,
  };
};

export default useUpdatePacingType;
