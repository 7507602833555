import type { ApolloError, DocumentNode, QueryLazyOptions } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import type { Column, TableInstance } from 'react-table';
import { useColumnOrder, useRowSelect, useSortBy } from 'react-table';

import type { NodeTransformFunction } from '../../../../apis/graphql';
import { openToastAlert } from '../../../../common/ToastAlert/toastAlert';
import type { TraffickingTableName } from '../../../../constants';
import type { ResultKeys } from '../../constants';
import type { EntityModel } from '../modelConverters';
import useRowSelectCheckboxColumn from './useRowSelectCheckboxColumn';
import type { QueryVariables } from './useTraffickerState';
import useTraffickerTableInstance from './useTraffickerTableInstance';

type LazyTraffickerTableState<Model extends EntityModel> = {
  tableInstance: TableInstance<Model>;
  loading: boolean;
  loadData: (options: QueryLazyOptions<QueryVariables>) => void;
};

const onError = (e: ApolloError): void => {
  openToastAlert({
    alertType: 'error',
    message: e.message,
    description: e.extraInfo,
  });
};

const useTraffickerExportTable = <NodeType, Model extends EntityModel>(
  query: DocumentNode,
  nodesKey: ResultKeys,
  transformFn: NodeTransformFunction<NodeType, Model>,
  columns: Column<Model>[],
  tableName: TraffickingTableName
): LazyTraffickerTableState<Model> => {
  const [loadData, { data, loading }] = useLazyQuery(query, { fetchPolicy: 'no-cache', onError });
  const [searchTerm, setSearchTerm] = useState<string>('');

  const plugins = [useSortBy, useRowSelect, useRowSelectCheckboxColumn, useColumnOrder];

  const tableInstance = useTraffickerTableInstance(
    data,
    nodesKey,
    transformFn,
    columns,
    tableName,
    { searchTerm, setSearchTerm },
    plugins
  );

  return {
    loadData,
    tableInstance,
    loading,
  };
};

export default useTraffickerExportTable;
