import Typography from '@hulu-react-style-components/typography';
import { themeUtils } from '@hulu-react-style-components/util';
import cx from 'classnames';
import React, { useCallback, useState } from 'react';
import type { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { ifProp, theme } from 'styled-tools';

const NOOP = (): void => {};

export interface DraggableTabNavigationProps {
  /** Extend the css classes applied to the component. */
  className?: string;
  /** Accepts only Tab element(s) as children. */
  children?: React.ReactElement<DraggableTabProps> | React.ReactElement<DraggableTabProps>[];
  /** Max available number of Tabs. */
  maxChildren?: number;
  /** Title of the selected tab. */
  selectedTab?: string;
  /** Index of the selected tab. */
  selectedIndex?: number;
  /** Callback is called when a Tab has clicked. */
  onTabClick?: React.EventHandler<any>;
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void;
  droppableId: string;
}

export interface DraggableTabProps {
  /** Flag to add/hide styles for selected Tab. */
  selected?: boolean;
  /** Tab will render any children elements if provided. */
  children?: React.ReactNode;
  /** Tab title. */
  title: string;
  /** Extend the css classes applied to the component. */
  className?: string;
  /** Tab will be replaced with element as a whole if provided. */
  element?: React.ReactElement;

  isDragDisabled?: boolean;

  [key: string]: any;
}

const DefaultTheme = {
  ...themeUtils.Theme.TabNavigation,
  ...themeUtils.Theme.Typography,
};

export const DraggableTab: React.FC<DraggableTabProps> = React.forwardRef(
  ({ children, className }: DraggableTabProps, ref?: React.Ref<HTMLDivElement>) => {
    return children ? (
      <div className={className} ref={ref}>
        {children}
      </div>
    ) : null;
  }
);

const StyledTab = styled(Typography)<DraggableTabProps>`
  color: ${ifProp(
    'selected',
    theme('TrekTabNavigationTab.pallete.foregroundActive'),
    theme('TrekTabNavigationTab.pallete.foreground')
  )};
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: ${theme('TrekTabNavigationTab.sizes.fontSize')};
  font-weight: ${theme('TrekTabNavigationTab.sizes.fontWeight')};
  line-height: ${ifProp(
    'selected',
    theme('TrekTabNavigationTab.sizes.lineHeightActive'),
    theme('TrekTabNavigationTab.sizes.lineHeight')
  )};
  padding: ${theme('TrekTabNavigationTab.sizes.padding')};
  height: ${theme('TrekTabNavigationTab.sizes.height')};
  margin: ${theme('TrekTabNavigationTab.sizes.margin')};
  position: relative;
  text-align: center;
  text-transform: uppercase;

  &:after {
    position: absolute;
    content: '';
    border-bottom: 4px solid
      ${ifProp('selected', theme('TrekTabNavigationTab.pallete.foregroundActive'), 'transparent')};
    width: 100%;
    transform: translateX(-50%);
    bottom: 0;
    left: 50%;
  }

  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }
`;
StyledTab.defaultProps = {
  theme: DefaultTheme,
};

const StyledTabNavigation = styled.nav`
  overflow-x: auto;
  text-align: left;
  display: flex;
`;

const getCustomElement = (element: React.ReactElement) => {
  // Here we wrap the provided element in a component, so we can add the custom styling while keeping any classnames and onClick handlers
  const WrappedComponent = ({ className, onClick }: { className: string; onClick: Function }) => {
    const newClassName = cx(className, element.props.className);
    const newOnClick = (...clickArgs: any[]) => {
      onClick();
      if (element.props.onClick) {
        element.props.onClick(...clickArgs);
      }
    };
    return React.cloneElement(element, {
      className: newClassName,
      onClick: newOnClick,
    });
  };
  return WrappedComponent;
};

const renderTabs = ({
  childrenArray,
  selected,
  onTabClickHandler,
}: {
  childrenArray: React.ReactElement[];
  selected: string | number;
  onTabClickHandler: React.EventHandler<any>;
}): React.ReactElement[] => {
  return childrenArray.map(({ props }: { props: DraggableTabProps }, index): any => {
    const { element, title, className, isDragDisabled, ...elementProps } = props;
    const isIndexed = typeof selected === 'number';
    const isSelected = isIndexed ? index === selected : title === selected;

    return (
      <Draggable
        draggableId={`${title}-${index}`}
        key={`${title}-${index}`}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(draggableProvided) => (
          <div
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
          >
            <StyledTab
              variant="navigation"
              component={element ? getCustomElement(element) : 'a'}
              selected={isSelected}
              onClick={() => onTabClickHandler(isIndexed ? { title, index } : title)}
              key={title + index}
              className={cx('TrekTabNavigationTab', 'TrekTabNavigationTab-root', className, {
                'TrekTabNavigationTab--selected': isSelected,
                'TrekTabNavigationTab-selected': isSelected,
              })}
              {...elementProps}
            >
              {title}
            </StyledTab>
          </div>
        )}
      </Draggable>
    );
  });
};

// This is copied solution from TabNavigation common component.
// We copied logic as we could not fit drag-n-drop functionality via TabNavigation.
const DraggableTabNavigation: React.ForwardRefExoticComponent<
  DraggableTabNavigationProps & React.RefAttributes<HTMLElement>
> = React.forwardRef(
  (
    {
      className,
      children,
      maxChildren = 5,
      selectedTab,
      selectedIndex,
      onTabClick,
      onDragEnd,
      droppableId,
      ...props
    }: DraggableTabNavigationProps,
    ref
  ) => {
    const childrenCount = React.Children.count(children);
    if (childrenCount > maxChildren) {
      throw new Error(`Only a max of ${maxChildren} NavigationItems can be added.`);
    }

    const childrenArray: any[] = React.Children.toArray(children);
    const [selectedState, setSelectedState] = useState(0);
    const selectTabHandler = useCallback(
      ({ index }: { index: number }) => {
        setSelectedState(index);
      },
      [setSelectedState]
    );
    // This allows the tabs to be used as controlled or uncontrolled.
    const indexed = selectedIndex !== undefined;
    const controlled = Boolean(indexed || selectedTab);
    const selectedData = indexed ? selectedIndex! : selectedTab!;
    const selected = controlled ? selectedData : selectedState;
    const onTabClickHandler: React.EventHandler<any> = controlled ? onTabClick || NOOP : selectTabHandler;

    const selectedChild =
      typeof selected === 'number'
        ? childrenArray[selected]
        : childrenArray.find(({ props }: { props: DraggableTabProps }) => props.title === selected);

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId} direction="horizontal">
          {(provided): React.JSX.Element => (
            <>
              <StyledTabNavigation
                className={cx('TrekTabNavigation', 'TrekTabNavigation-root', className)}
                {...props}
                ref={provided.innerRef || ref}
              >
                {renderTabs({
                  childrenArray,
                  selected,
                  onTabClickHandler,
                })}
                {provided.placeholder}
              </StyledTabNavigation>
              {selectedChild}
            </>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
);

export default DraggableTabNavigation;
