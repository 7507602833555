import { format } from 'date-fns';
import { upperFirst } from 'lodash';
import type { Cell, CellValue, ColumnInstance, TableInstance } from 'react-table';

import { TraffickingTableName } from '../../../constants';
import type { AlertFlagProps } from '../TraffickingPage/cells/AlertCell';
import { boolToYes } from '../TraffickingPage/columns';
import type { EntityModel } from '../TraffickingPage/modelConverters';
import type { ColumnsValueAccessors } from './config';
import {
  adsColumnsCustomValueAccessors,
  ALERT_FIELD_TO_DISPLAY_NAME_CONFIG,
  campaignsCustomValueAccessors,
  columnsValueAccessors,
  lineItemsCustomValueAccessors,
} from './config';

const headerColumnsToIgnore = ['CreateAd', 'EditAd', 'deleteAd'];
const rowCellsToIgnore = [...headerColumnsToIgnore, 'selection'];

export function formatBooleanToYes(cell: Cell<EntityModel>) {
  return boolToYes(cell) ?? '';
}

export const formatAlertColumnToCorrectValue = (alertFlags: AlertFlagProps[]) =>
  alertFlags
    .reduce<string[]>((acc, { isShown, field }) => {
      if (isShown) {
        acc.push(ALERT_FIELD_TO_DISPLAY_NAME_CONFIG[field]);
      }

      return acc;
    }, [])
    .join(', ');

const getHeaderColumns = (columns: ColumnInstance<EntityModel>[]): string[] => {
  const headerColumns = columns.reduce(
    (acc, column) => {
      if (!column.isVisible || typeof column.Header !== 'string' || headerColumnsToIgnore.includes(column.id)) {
        return acc;
      }

      return [...acc, column.Header];
    },
    ['Selection']
  );

  return headerColumns;
};

export const formatToCSVValue = (value?: string | null | boolean): string => {
  if (value) {
    // According to CVS spec:REF-4180 If double quotes are used
    // then another double quotes must be before it
    return value.toString().replace(/"/g, (match: string) => `"${match}`);
  }

  return '';
};

export const formatObjectToCSVValue = (value?: CellValue): string => {
  return value?.name?.displayName || value?.displayName || '';
};

const getAccessorValue = (cell: Cell<EntityModel>, tableName: TraffickingTableName): string => {
  const customAccessorsMap: Record<TraffickingTableName, ColumnsValueAccessors> = {
    [TraffickingTableName.ads]: adsColumnsCustomValueAccessors,
    [TraffickingTableName.campaigns]: campaignsCustomValueAccessors,
    [TraffickingTableName.lineItems]: lineItemsCustomValueAccessors,
  };

  const accessor = customAccessorsMap[tableName]?.[cell.column.id] || columnsValueAccessors[cell.column.id];

  return accessor ? accessor(cell) : formatToCSVValue(cell.value);
};

const getRowsValues = (tableInstance: TableInstance<EntityModel>, tableName: TraffickingTableName): string[][] => {
  return tableInstance.rows.map((row) => {
    tableInstance.prepareRow(row);

    const rowValues: string[] = row.cells.reduce<string[]>((acc, cell) => {
      if (!rowCellsToIgnore.includes(cell.column.id)) {
        acc.push(getAccessorValue(cell, tableName));
      }

      return acc;
    }, []);

    return [row.isSelected ? '+' : '-', ...rowValues];
  });
};

export const getDataToExport = (
  tableName: TraffickingTableName,
  tableInstance?: TableInstance<EntityModel>
): string[][] => {
  if (!tableInstance) return [];

  const headerColumns = getHeaderColumns(tableInstance.allColumns);
  const rowsValues = getRowsValues(tableInstance, tableName);

  return [headerColumns, ...rowsValues];
};

const convertCurrentDateToExportFileNameDate = (date: Date): string => {
  return format(date, "MMddyy'_'HHmma");
};

export const convertExportFileNameToCorrectFormat = (tableName: TraffickingTableName, date: Date): string => {
  return `${upperFirst(tableName).replace(/ /g, '')}_${convertCurrentDateToExportFileNameDate(date)}`;
};
