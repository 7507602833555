import type { Get_CampaignsQuery } from '../../../../apis/graphql';
import { convertOrderType } from '../../../../configs';
import type { AdAccount, Campaign, CrmLink, Nullable, OrderOmsLink } from '../../../../models';

export type CampaignNode = NonNullable<Get_CampaignsQuery['campaigns']['edges'][number]>['node'];

export type CampaignModel = Pick<
  Campaign,
  | 'advertiser'
  | 'agency'
  | 'industryName'
  | 'budget'
  | 'createdAt'
  | 'endDate'
  | 'hasZeroDeliveryRisk'
  | 'hasMisalignedSchedule'
  | 'id'
  | 'traffickerEmail'
  | 'name'
  | 'salespersonEmail'
  | 'orderOmsLink'
  | 'orderType'
  | 'startDate'
  | 'updatedAt'
  | 'trackingId'
  | 'lineItemList'
> & {
  adAccount?: Nullable<Pick<AdAccount, 'name' | 'id' | 'timezone' | 'organizationId'>>;
  orderOmsLink: Nullable<Pick<OrderOmsLink, 'id' | 'type'>>;
  crmLink: Nullable<CrmLink>;
};

export function campaignNodeToModel(node: CampaignNode): CampaignModel {
  const { adAccount, order, orderType, hasZeroDeliveryRisk, hasMisalignedSchedule } = node;

  return {
    ...node,
    salespersonEmail: order?.salespersonEmail ? order?.salespersonEmail : null,
    orderType: orderType ? convertOrderType(orderType) : null,
    hasZeroDeliveryRisk: !!hasZeroDeliveryRisk,
    hasMisalignedSchedule: !!hasMisalignedSchedule,
    adAccount: adAccount
      ? {
          ...adAccount,
          organizationId: adAccount?.organizationId || '',
        }
      : undefined,
  };
}
