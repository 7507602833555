import type { FormikProps, FormikState } from 'formik';
import { useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';
import { usePrevious } from 'react-use';

import type { AdFormValues } from '../AdForm/adFormik';
import { useConstant } from './useConstant';

export type PartialFormState = Partial<FormikState<AdFormValues>>;

export type FormStateWithInitialValues = PartialFormState & {
  initialValues: AdFormValues;
  values: AdFormValues;
};

interface FormStateManager {
  dirty: boolean;
  initialValues: AdFormValues;
  resetForm: FormikProps<AdFormValues>['resetForm'];
  setFormState: (state: FormStateWithInitialValues) => void;
  currentFormState: PartialFormState;
  prevFormState?: PartialFormState;
}

export function useFormStateManager(): FormStateManager {
  const {
    dirty,
    resetForm,
    setValues,
    initialValues: currentInitialValues,
    values,
    errors,
  } = useFormikContext<AdFormValues>();

  // whatever Formik's initialValues are during the very first call of the setFormStateManager,
  // those are the initialValues we'll use as fallbacks or defaults
  const initialValues = useConstant(currentInitialValues);

  // we are memoizing both of these for use in tracking whether or not the form has been updated
  const currentFormState = useMemo(() => ({ errors, values }), [errors, values]);
  const prevFormState = usePrevious(currentFormState);

  // setFormState reset's the current form to a newState
  const setFormState = useCallback(
    (newState: FormStateWithInitialValues) => {
      // use the new state's initialValues property when resetting the form
      resetForm({ ...newState, values: newState.initialValues });

      // then update the form's values to be that new state's values to properly track the presence of changes
      setValues({ ...newState.initialValues, ...newState.values });
    },
    [resetForm, setValues]
  );

  return {
    dirty,
    initialValues,
    resetForm,
    setFormState,
    currentFormState,
    prevFormState,
  };
}
