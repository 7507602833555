import { gql } from '@apollo/client';

export const GET_AGENCIES = gql`
  query GET_AGENCIES($limit: Int, $offset: Int, $searchTerm: String) {
    getAgencies(paginationOptions: { limit: $limit, offset: $offset }, filterOptions: { name: $searchTerm }) {
      edges {
        node {
          id
          url
          type
          name
          indexedAt
        }
      }
      pageInfo {
        hasNextPage
      }
    }
  }
`;
